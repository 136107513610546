const STORAGE = window.localStorage;
STORAGE.removeItem("clientListCache");
import moment from 'moment';
import configStore from "./services/ConfigurationService";
import auth from "./auth";

export default {
  propToArray: function (props) {
    var result = [];
    for (var k in props) {
      result.push(props[k]);
    }
    return result;
  },
  copyJson: function (json) {
    return JSON.parse(JSON.stringify(json));
  },
  log: function (obj) {
    console.log(JSON.parse(JSON.stringify(obj)));
  },
  offsetDate: function (v) {
    if (v !== null || v !== undefined || v !== "") {
      let d = new Date(v);
      return new Date(d.getTime() + d.getTimezoneOffset() * 60000);
    }
    return v;
  },
  arrayToObj(array, key) {
    return Object.fromEntries([...array.entries()].map(([i, obj]) => [obj[key], obj]));
  },
  async chunkedClientFetch(http, facId) {
    const keyForSingleFac = "single_fac";
    let cache = JSON.parse(STORAGE.getItem("clientListCache"));
    let clients = [];
    if (cache && (cache[facId] || cache[keyForSingleFac])) {
      clients = cache[facId] || cache[keyForSingleFac];
    } else {
      const url = page => `${window.CONFIG.api}/clients/list/unit/0/page/${page}?sort=firstName&size=30${facId ? `&facIds=${facId}` : ""}`;
      let page = 0;
      let isLastPage = false;

      while (isLastPage === false) {
        const response = await http.get(url(page));
        clients = [...clients, response.body.content];
        isLastPage = response.body.last;
        page += 1;
      }
      clients = clients.flat();
      cache = cache || {};
      cache[facId || keyForSingleFac] = clients;
      try {
        STORAGE.setItem("clientListCache", JSON.stringify(cache));
      } catch (e) {
        console.log(e);
      }
    }

    return clients;
  },
  sortWithFixedLeadingElements: function (array, ...elements) {
    const set = new Set(array);
    elements.forEach(e => set.delete(e));
    return [...elements, ...Array.from(set).sort()];
  },
  // default datepicker shortcuts used across ./Shared/FiltersSection.vue
  datepickerShortcuts() {
    return [
      {
        text: "Last 7 Days",
        onClick(picker) {
          const start = moment().subtract(6, "days").toDate();
          const end = moment().toDate();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last 30 Days",
        onClick(picker) {
          const end = moment().toDate();
          const start = moment().subtract(30, "days").toDate();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last 90 Days",
        onClick(picker) {
          const end = moment().toDate();
          const start = moment().subtract(90, "days").toDate();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last week",
        onClick(picker) {
          const end = moment().weekday(-1).toDate();
          const start = moment().weekday(-7).toDate();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last month",
        onClick(picker) {
          const lastMonth = moment().subtract(1, "months");
          const end = lastMonth.endOf("month").toDate();
          const start = lastMonth.startOf("month").toDate();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last year",
        onClick(picker) {
          const lastYear = moment().subtract(1, "years");
          const end = lastYear.endOf("year").toDate();
          const start = lastYear.startOf("year").toDate();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Current week",
        onClick(picker) {
          const end = moment().weekday(6).toDate();
          const start = moment().weekday(0).toDate();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Current month",
        onClick(picker) {
          const lastMonth = moment();
          const end = lastMonth.endOf("month").toDate();
          const start = lastMonth.startOf("month").toDate();
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Current year",
        onClick(picker) {
          const end = moment().endOf("year").toDate();
          const start = moment().startOf("year").toDate();
          picker.$emit("pick", [start, end]);
        },
      }
    ]
  },
  generateExportDetailsRow() {
    const username = auth.userInfo().username;
    const date = moment().format(configStore.dateFormat());
    const detailsRow = `Report generated by HealthConnex on ${date} by ${username}`
    return [detailsRow];
  }
};
