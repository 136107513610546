<template>
  <div class="infection-ai" v-loading="isGenerating">
    <el-dialog v-model="chartGenerationFailed" title="Error" size="small" :top="'30%'" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <span class="error-dialog-text">An error has been encountered in chart generation. Please try again later</span>
      <br />
      <br />
      <el-button @click="closeErrorDialog">Ok</el-button>
    </el-dialog>
    <div v-if="form.displayChartForm">
      <span class="header">What can I help with?</span>
      <div class="chart-form">
        <div class="form-selector" v-if="!form.displayPrebuiltForm && !form.displayCustomForm">
          <el-button class="form-selector-button" @click="() => {form.displayPrebuiltForm = true}">
            <img class="icon" src="../../../assets/icons/infection-ai/pencil-edit-icon.svg" width="15" height="15" />
            <span class="form-selector-text">Choose a template</span>
          </el-button>
          <el-button class="form-selector-button" @click="() => {form.displayCustomForm = true}">
            <img class="icon" src="../../../assets/icons/infection-ai/blank-sticky-note-icon.svg" width="16" height="16" />
            <span class="form-selector-text">Build your own chart</span>
          </el-button>
          <el-button class="form-selector-button" @click="viewChartHistory" v-if="chart.summary.length > 0">
            <img class="icon" src="../../../assets/icons/infection-ai/table-of-contents-icon.svg" width="14" height="14" />
            <span class="form-selector-text">View chart history</span>
          </el-button>
        </div>
        <div v-if="form.displayPrebuiltForm">
          <el-row class="chart-form-row">
            <el-col :span="12">
              <span class="chart-form-text">Choose a template: </span>
            </el-col>
            <el-col :span="12">
              <el-select size="medium" v-model="prompt.selectedPrebuiltQuery">
                <el-option v-for="(value, key) in prompt.prebuiltQueries" :key="key" :value="value.value" :label="value.label" />
              </el-select>
            </el-col>
          </el-row>
          <el-row class="chart-form-row">
            <el-col :span="12">
              <span class="chart-form-text">Select a date range: </span>
            </el-col>
            <el-col :span="12">
              <el-date-picker v-model="prompt.dateRange" type="daterange" :format="$configStore.datePickerDateFormat()" :picker-options="form.pickerOptions">
                <template slot="prepend">Http://</template>
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row class="chart-form-row">
            <el-col :span="12">
              <span class="chart-form-text">Select your facilities: </span>
            </el-col>
            <el-col :span="12">
              <el-select size="medium" v-model="prompt.selectedFacIds" multiple>
                <el-option v-for="(value, key) in this.$configStore.data.sites" :key="key" :value="value.id" :label="value.displayText" />
              </el-select>
            </el-col>
          </el-row>
        </div>
        <div v-if="form.displayCustomForm">
          <el-row class="chart-form-row">
            <el-col :span="12">
              <span class="chart-form-text">Select what datasets you want to connect: </span>
            </el-col>
            <el-col :span="12">
              <el-select v-model="prompt.datasets" multiple @change="onDatasetsChange">
                <el-option label="Outbreaks" value="Outbreaks" />
                <el-option label="Hand Hygiene" value="Hand Hygiene" />
                <el-option label="Infection Cases" value="Infection Cases" />
                <el-option label="Treatments/Medications" value="Treatments/Medications" />
              </el-select>
            </el-col>
          </el-row>
          <el-row class="chart-form-row">
            <el-col :span="12">
              <span class="chart-form-text">Select a date range: </span>
            </el-col>
            <el-col :span="12">
              <el-date-picker v-model="prompt.dateRange" type="daterange" :format="$configStore.datePickerDateFormat()" :picker-options="form.pickerOptions">
                <template slot="prepend">Http://</template>
              </el-date-picker>
            </el-col>
          </el-row>
          <div v-for="(value, key) in prompt.datasets" :key="key">
            <el-row class="chart-form-row">
            <el-col :span="12">
                <span class="chart-form-text">What {{value}} data do you want to see:</span>
            </el-col>
            <el-col :span="12">
                <el-input class="chart-form-input" v-model="prompt.series[key]" :placeholder="`Ex. ${form.exampleSeries[value]}`" />
                <br />
                <el-select size="small" v-model="prompt.chartTypes[key]" placeholder="Select a chart type">
                  <el-option label="Column Chart" value="column"> </el-option>
                  <el-option label="Line Chart" value="line"> </el-option>
                </el-select>
            </el-col>
            </el-row>
          </div>              
        </div>
        <el-button class="generate-chart-button" v-if="form.displayPrebuiltForm || form.displayCustomForm" @click="generateChart">Generate Chart</el-button>
      </div>
    </div>
    <div v-else>
    <page-section title="Graph">
      <template slot="title">
        <span class="section-label">Select a chart:</span>
        <el-select size="small" v-model="chart.selectedId" @change="onSelectedChartIdChange" placeholder="6">
          <el-option v-for="(value, key) in chart.summary" :key="key" :value="value.id" :label="`Chart #${key + 1} - ${value.title}`" />
        </el-select>
      </template>
      <highcharts ref="highchartsChart" class="chart" v-if="chart.config" :options="chart.config"></highcharts>
    </page-section>
    <page-section title="Reporting Assistant">
      <el-row>
        <el-col :span="16">
        <div class="chat-container">
          <div class="chat-messages" ref="chatMessages">
            <el-card class="message" v-for="(message, index) in chatSection.messages" :key="index"
            :class="{'user-message': message.sender === chatSection.user, 'system-message': message.sender === chatSection.system}">
              <span><b>{{ message.sender }}:</b></span>
              <span>{{ message.message }}</span>
            </el-card>
          </div>
          <el-row>
            <el-col :span="22">
              <el-input v-model="chatSection.newMessage" placeholder="Type a message..." @keyup.enter.native="sendMessage" clearable />
            </el-col>
            <el-col :span="2">
              <el-button @click="sendMessage">Send</el-button>
            </el-col>
          </el-row>
        </div>
        </el-col>
        <el-col :span="8">
        <el-dialog v-model="suggestions.displayDateRangeSelector" title="Select a date range" size="small" :top="'30%'" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true">
          <el-date-picker v-model="suggestions.dateRange" type="daterange" :format="$configStore.datePickerDateFormat()" :picker-options="form.pickerOptions">
            <template slot="prepend">Http://</template>
          </el-date-picker>
          <el-button @click="selectDateRange">Ok</el-button>
        </el-dialog>
        <el-dialog v-model="suggestions.displayMonthSelector" title="Select a month" size="small" :top="'30%'" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true">
          <el-select v-model="suggestions.selectedMonth" size="small">
            <el-option v-for="(value, key) in suggestions.months" :key="key" :value="value" :label="value" />
          </el-select>
          <el-button @click="selectMonth">Ok</el-button>
        </el-dialog>

          <el-row class="prebuilt-message-row">
            <span class="section-heading">Suggestions:</span>
          </el-row>

          <el-row class="prebuilt-message-row">
            <el-button class="prebuilt-message-button" @click="loadPrebuiltMessage('Group the data by day and change the tick interval to day')">Group by day</el-button>
            <el-button class="prebuilt-message-button" @click="loadPrebuiltMessage('Group the data by week and change the tick interval to week')">Group by week</el-button>
            <el-button class="prebuilt-message-button" @click="loadPrebuiltMessage('Group the data by month and change the tick interval to month')">Group by month</el-button>
            <el-button class="prebuilt-message-button" @click="loadPrebuiltMessage('Group the data by quarter and change the tick interval to quarter')">Group by quarter</el-button>
          </el-row>
          <el-row class="prebuilt-message-row">
            <el-button class="prebuilt-message-button" @click="selectMonth">Zoom in on a month</el-button>
            <el-button class="prebuilt-message-button" @click="selectDateRange">Change the date range</el-button>
          </el-row>
          <el-row class="prebuilt-message-row">
            <el-button class="generate-new-chart-button" @click="refreshChartForm">
              <span class="generate-new-chart-text">Generate a new chart</span>
            </el-button>
          </el-row>
        </el-col>
      </el-row>
    </page-section>
    </div>
  </div>
</template>

<script>
import { Chart as highcharts } from "highcharts-vue";
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more'; 
import moment from "moment";
import auth from "../../../auth";
import PageSection from "../../Shared/PageSection";

HighchartsMore(Highcharts);
export default {
  name: "infection-ai-page",
  components: {
    highcharts,
    "page-section": PageSection,
  },
  mounted() {
    this.getChartSummary();
  },
  data() {
    return {
      isGenerating: false,
      chartGenerationFailed: false,
      prompt: {
      prebuiltQueries: [
        {
          label: "An overlay of Hand Hygiene compliance rates with Infection cases, per week", 
          value: ["Dataset: Infection Cases - Series: Number of infections per week - Chart type: column","Dataset: Hand Hygiene - Series: Hand hygiene compliance percentage per week - Chart type: line"]
        },
        {
          label: "An overlay of Hand Hygiene compliance rates with Outbreaks cases, per month", 
          value: ["Dataset: Outbreaks - Series: Number of outbreaks per month - Chart type: column","Dataset: Hand Hygiene - Series: Hand hygiene compliance percentage per month - Chart type: line"]
        },
        {
          label: "Infection cases and Outbreak over time", 
          value: ["Dataset: Outbreaks - Series: Number of outbreaks per month - Chart type: line","Dataset: Infection Cases - Series: Number of infections per month - Chart type: line"]
        },
        {
          label: "Average length of therapy of medications for each medication type", 
          value: ["Dataset: Treatments/Medications - Series: Average length of therapy of medications prescribed, grouped by medication - Chart type: column"]
        },
      ],
        selectedPrebuiltQuery: "",
        datasets: [],
        dateRange: [],
        series: [],
        chartTypes: [],
        selectedFacIds: []
      },
      form: {
        displayChartForm: true,
        displayPrebuiltForm: false,
        displayCustomForm: false,
        exampleSeries: {
          'Outbreaks': 'Number of outbreak cases per week',
          'Hand Hygiene': 'Hand hygiene compliance percentage per month',
          'Infection Cases': 'Number of infections per week',
          'Treatment/Medications': 'TODO: ask Alex'
        },
        pickerOptions: {
          shortcuts: [
            {
              text: "Last week",
              onClick(picker) {
                const time = moment().subtract(1, "weeks");
                const end = time.endOf("week").toDate();
                const start = time.startOf("week").toDate();
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Last month",
              onClick(picker) {
                const lastMonth = moment().subtract(1, "months");
                const end = lastMonth.endOf("month").toDate();
                const start = lastMonth.startOf("month").toDate();
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Last 90 Days",
              onClick(picker) {
                const end = moment().toDate();
                const start = moment()
                  .subtract(90, "days")
                  .toDate();
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Last quarter",
              onClick(picker) {
                const lastQ = moment().subtract(1, "quarters");
                const end = lastQ.endOf("quarter").toDate();
                const start = lastQ.startOf("quarter").toDate();
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Last year",
              onClick(picker) {
                const lastYear = moment().subtract(1, "years");
                const end = lastYear.endOf("year").toDate();
                const start = lastYear.startOf("year").toDate();
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Current week",
              onClick(picker) {
                const time = moment();
                const end = time.endOf("week").toDate();
                const start = time.startOf("week").toDate();
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Current month",
              onClick(picker) {
                const lastMonth = moment();
                const end = lastMonth.endOf("month").toDate();
                const start = lastMonth.startOf("month").toDate();
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Current quarter",
              onClick(picker) {
                const end = moment()
                  .endOf("quarter")
                  .toDate();
                const start = moment()
                  .startOf("quarter")
                  .toDate();
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "Current year",
              onClick(picker) {
                const end = moment()
                  .endOf("year")
                  .toDate();
                const start = moment()
                  .startOf("year")
                  .toDate();
                picker.$emit("pick", [start, end]);
              }
            }
          ]
        }
      },
      chatSection: {
        messages: [],
        newMessage: '',
        user: auth.userInfo().username,
        system: 'Assistant'
      },
      suggestions: {
        displayDateRangeSelector: false,
        displayMonthSelector: false,
        dateRange: [],
        selectedMonth: "",
        months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      },
      chart: {
        config: {},
        summary: [],
        selectedId: 0,
      },
    };
  },
  methods: {
    handleError(err) {
      this.refreshChartForm();
      this.chartGenerationFailed = true;
    },
    closeErrorDialog() {
      this.chartGenerationFailed = false;
    },
    loadPrebuiltMessage(message) {
      this.chatSection.newMessage = message;
      this.sendMessage();
    },
    onSelectedChartIdChange() {
      this.getChartData(this.chart.selectedId);
    },
    onDatasetsChange() {
      this.prompt.series = new Array(this.prompt.datasets.length);
      this.prompt.chartTypes = new Array(this.prompt.datasets.length);
    },
    selectDateRange() {
      if (this.suggestions.displayDateRangeSelector) {
        this.suggestions.displayDateRangeSelector = false;
        this.loadPrebuiltMessage(`Change the date range to the following: ${this.suggestions.dateRange[0]} - ${this.suggestions.dateRange[1]}`);
        return;
      } else {
        this.suggestions.displayDateRangeSelector = true;
        return;
      }
    },
    selectMonth() {
      if (this.suggestions.displayMonthSelector) {
        this.loadPrebuiltMessage(`Group by weeks and zoom in on the month: ${this.suggestions.selectedMonth}`);
      }
      this.suggestions.displayMonthSelector = !this.suggestions.displayMonthSelector;
    },
    async viewChartHistory() {
      this.form.displayChartForm = false;
      await this.getChartSummary();
    },
    refreshChartForm() {
      this.form.displayChartForm = true;
      this.form.displayPrebuiltForm = false;
      this.form.displayCustomForm = false;

      this.prompt.selectedPrebuiltQuery = "";
      this.prompt.datasets = [];
      this.prompt.dateRange = [];
      this.prompt.series = [];
      this.prompt.chartTypes = [];
      this.prompt.selectedFacIds = [];

      this.chatSection.messages = [];
      this.chatSection.newMessage = "";

    },
    async sendMessage() {
      if (this.chatSection.newMessage !== '') {
        const newestMessage = this.chatSection.newMessage;
        this.chatSection.newMessage = '';
        this.addMessage(this.chatSection.user, newestMessage);

        const data = await this.$http
          .post(`${window.CONFIG.infection_ai_api}/chart/message`, {
            message: this.chatSection.messages.filter(value => {
              return value.sender === this.chatSection.user
            }),
            chartId: this.chart.selectedId
          })
          .then((response) => response.body)
          .catch((err) => this.handleError(err));
        if (data === "generateChart") {
          this.chatSection.messages.push({
            sender: "Assistant",
            message: "Please wait while we generate your chart",
          });

          await this.$http
          .put(`${window.CONFIG.infection_ai_api}/chart/regenerate`, {
            message: this.chatSection.messages.filter(value => {
              return value.sender === this.chatSection.user
            }),
            chartId: this.chart.selectedId
          })
          .then((response) => response.body)
          .catch((err) => this.handleError(err));
          this.getChartSummary();
        } else {          
          this.chatSection.messages.push({
            sender: "Assistant",
            message: data,
          });
        }
      
      }
    },
    addMessage(sender, message) {
      this.chatSection.messages.push({
        sender: sender,
        message: message,
      });
    },
    async generateChart() {
      this.isGenerating = true;
      let seriesData = [];
      if (this.form.displayPrebuiltForm) {
        seriesData = this.prompt.selectedPrebuiltQuery;
      } else {
        this.prompt.datasets.forEach((value, index) => {
          seriesData.push(`Dataset: ${this.prompt.datasets[index]} - Series: ${this.prompt.series[index]} - Chart type: ${this.prompt.chartTypes[index]}`);
        })
      }
      await this.$http
        .put(`${window.CONFIG.infection_ai_api}/chart/generate`, {
          requestedSeries: seriesData,
          dateRange: this.prompt.dateRange,
          facIds: this.prompt.selectedFacIds,
          user: auth.userInfo().username
        })
        .then((response) => response.body)
        .catch((err) => this.handleError(err));
      this.chatSection.messages.push({
        sender: this.chatSection.system,
        message: "Your chart has been generated! If there's anything you would like to change, feel free to ask me about it or try one of the suggestions on the right.",
      })
      this.viewChartHistory();
      this.isGenerating = false;
    },
    async getChartSummary() {
      this.chart.summary = await this.$http
        .post(`${window.CONFIG.infection_ai_api}/chart/summary`, { user: auth.userInfo().username })
        .then((response) => response.body)
        .catch((err) => this.handleError(err));

      if (this.chart.summary.length > 0) {
        this.chart.selectedId = this.chart.summary[this.chart.summary.length - 1].id;
        this.getChartData(this.chart.selectedId);
      }
    },
    async getChartData(chartId) {
      this.chart.config = {};
      this.chart.config = await this.$http
        .post(`${window.CONFIG.infection_ai_api}/chart/view`, { chartId: chartId })
        .then((response) => {
          return response.body

        })
        .catch((err) => this.handleError(err));
      if (this.$refs.highchartsChart) {
        this.$refs.highchartsChart.chart.redraw();
      }          
    },
  },
};
</script>

<style scoped>
.form-selector {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.form-selector-button {
  border-radius: 20px;
}
.form-selector .icon {
  margin-right: 5px;
}
.chart-form {
  margin: auto;
}
.form-selector-text {
  font-size: 16px !important;
}
.header {
  font-size: 24px !important;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-top: 15%;
}
.chart-form-row {
  margin-top: 10px;
}
.chart-form-input {
  width: 600px;
}
.chart-form-text {
  float: right;
  margin-right: 10px;
  margin-top: 3px;
  font-size: 18px !important;
}
.generate-chart-button {
  display: block;
  margin: 0 auto;
  margin-top: 40px;
}
.section-label {
  margin-right: 5px;
}
.chat-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 300px;
  border: 1px solid #e2e2e2;
  padding: 10px;
  margin-top: 10px;
  margin-left: 20px;
  padding-right: 0px;
}
.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 5px;
  align-content: right;
}
.message {
  margin-bottom: 5px;
}
.section-heading {
  margin-top: 10px;
  font-size: 16px !important;
  font-weight: bold;
  padding-left: 5px;
  padding-top: 5px;
}
.prebuilt-message-button {
  padding: 7px 12px;
  border-radius: 15px;
}
.prebuilt-message-row {
  margin-top: 10px;
}
.user-message {
  background-color: #cee7f0;
  clear: both;
  text-align: right;
}
.generate-new-chart-button {
  margin-top: 50px;
  padding: 15px;
  border-radius: 20px;
}
.generate-new-chart-text {
  font-size: 16px !important;
}
.generate-new-chart-icon {
  top: 5px;
  right: 5px;
  position: relative;
}
.error-dialog-text {
  font-size: 16px !important;
  margin-bottom: 20px;
}
</style>