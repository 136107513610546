<template>
  <div class="accounts">
    <filters-section :showLocation="true" :includeUnits="false" :filterDisplayKeys="getFilterKeys()" v-on:change="formatFilters"> </filters-section>
    <page-section title="User Accounts" v-loading.body="loading">
      <template slot="title">
        <el-button size="small" @click="exportAccounts()">Export Data</el-button>

        <el-button size="small" class="accounts-button" @click="addUser($event)" :disabled="!allowedToCreate()">+ Add User</el-button>

        <el-select class="accounts-select" size="mini" v-model="pagination.size" placeholder="Choose page size">
          <el-option v-for="item in pageSizeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>

        <el-pagination small layout="prev, pager, next" :current-page="pagination.page" :page-count="pagination.totalPages" @current-change="updatePageNumber"> </el-pagination>
      </template>
      <el-table :data="accounts" stripe style="width: 100%">
        <el-table-column prop="username" label="Username" sortable> </el-table-column>
        <el-table-column prop="email" label="Email" sortable> </el-table-column>
        <el-table-column prop="firstName" label="First Name" sortable> </el-table-column>
        <el-table-column prop="lastName" label="Last Name" sortable> </el-table-column>
        <el-table-column label="Status" prop="status" width="75">
          <template scope="scope">
            <span :class="scope.row.status ? '' : 'inactive-user'">{{ scope.row.status ? "Active" : "Inactive" }}</span>
          </template>
        </el-table-column>
        <el-table-column :formatter="formatArray" prop="visibleFacilitiesArray" label="Facility" width="200" sortable> </el-table-column>
        <el-table-column :formatter="formatArray" prop="visibleRoles" label="Roles" sortable> </el-table-column>
        <el-table-column fixed="right" label="Operations" width="150">
          <template slot-scope="scope">
            <!-- button to mark inactive -->
            <el-button size="mini" :disabled="!allowedToEdit()" @click="updateUser($event, scope.row.username)">
              <Icon :iconKey="'edit'" :description="'Edit User'" />
            </el-button>
            <!-- button to open the editor dialogue in edit mode -->
            <el-button size="mini" :disabled="!allowedToEdit()" @click="toggleActive(scope.row)">
              <Icon :iconKey="scope.row.status ? 'toggleOff' : 'toggleOn'" :description="scope.row.status ? 'Deactivate User' : 'Activate User'" />
            </el-button>
            <!-- button to open the password reset dialogue -->
            <el-button size="mini" :disabled="!allowedToEdit()" @click="showPasswordReset(scope.row.id)">
              <Icon :iconKey="'key'" :description="'Edit Password'" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </page-section>
    <uam-editor :title="editor.title" :values="editor.values" :submitText="editor.submitText" :isCreate="editor.isCreate" :availableRolesArray="availableRolesArray" :availableRoles="availableRoles" v-if="editor.visible" v-on:editor-close="cancelEditor" v-on:editor-error="showError" v-on:editor-save="showSuccess"></uam-editor>
    <uam-password-reset :userId="passwordReset.userId" v-if="passwordReset.visible" v-on:password-reset-close="cancelPasswordReset" v-on:password-reset-error="showError" v-on:password-reset-save="showSuccess"></uam-password-reset>

    <progress-overlay v-if="accountExport.exporting" :progress="accountExport.progress" v-on:progress-cancelled="cancelAccountExport" title="Download In Progress"></progress-overlay>

    <el-dialog title="Error, please try again" v-model="accountExport.errorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" size="tiny">
      There was an error exporting Accounts. Please try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            accountExport.errorDialog = false;
            exportAccounts();
          "
          >Retry</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import auth from "../../auth";
import PageSection from "../Shared/PageSection";
import FiltersSection from "../Shared/FiltersSection";
import ProgressOverlay from "../ProgressBarOverlay";
import Icon from "../Shared/Icon";
import Editor from "./Editor";
import PasswordReset from "./PasswordReset.vue";
import XLSX from "xlsx";
import util from "@/util";

export default {
  name: "accounts",
  data: function () {
    return {
      isMultiFacility: false,
      accounts: [],
      filters: {},
      availableFacilities: [],
      editor: {
        title: "Add User",
        visible: false,
        isCreate: true,
        submitText: "Save",
        message: "",
        values: null,
      },
      accountExport: {
        exporting: false,
        errorDialog: false,
        progress: 0,
      },
      passwordReset: {
        visible: false,
        userId: "",
      },
      pagination: {
        size: 10,
        page: 1,
        totalElements: 0,
        totalPages: 0,
      },
      pageSizeOptions: [
        {
          label: "5 items per page",
          value: 5,
        },
        {
          label: "10 items per page",
          value: 10,
        },
        {
          label: "20 items per page",
          value: 20,
        },
      ],
      availableRolesArray: [],
      availableRoles: {},
      loading: true,
    };
  },
  computed: {
    size: function () {
      return this.pagination.size;
    },
  },
  watch: {
    size: function (size) {
      this.updatePageSize(size);
    },
  },
  components: {
    "page-section": PageSection,
    "uam-editor": Editor,
    "uam-password-reset": PasswordReset,
    "filters-section": FiltersSection,
    "progress-overlay": ProgressOverlay,
    Icon,
  },
  methods: {
    getFilterKeys() {
      let filters = ["userStatus", "username", "roles"];
      return filters;
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
            }
            return [k, v];
          })
      );

      if (rawFilters.userStatus === "active") {
        filterValues.status = true;
      } else if (rawFilters.userStatus === "inactive") {
        filterValues.status = false;
      }
      delete filterValues.userStatus;
      this.filters = { ...filterValues };

      this.loadAccounts();
    },
    async loadAccounts() {
      this.loading = true;
      try {
        const filters = {
          ...this.filters,
          size: this.pagination.size,
          page: this.pagination.page,
        };
        const response = await this.$http.get(window.CONFIG.uam_api + "/users", { params: filters });
        const { totalElements, totalPages, content } = response.body;
        this.accounts = this.generateAccountTableData(content);
        this.pagination.totalElements = totalElements;
        this.pagination.totalPages = totalPages;
        this.loading = false;
      } catch (err) {
        this.accounts = [];
        this.loading = false;
      }
    },
    async loadAccountsForExport() {
      const filters = {
        ...this.filters,
        size: this.pagination.size,
        page: this.pagination.page,
        export: true,
      };
      const response = await this.$http.get(window.CONFIG.uam_api + "/users", { params: filters });
      return response.body;
    },
    generateAccountTableData(records) {
      return records.map((account) => {
        // visibleFacilities are facilities in this.availableFacilities (show only current user's facilities)
        // visibleRoles are roles in this.availableRoles (show only user level roles)
        const visibleFacilities = account.facilities.map((facId) => this.availableFacilities[facId] || null).filter((fac) => fac);
        const visibleFacilitiesArray = visibleFacilities.map((fac) => fac.displayText);
        return {
          ...account,
          visibleFacilities,
          visibleFacilitiesArray,
          visibleRoles: account.roles.filter((role) => this.availableRoles[role]).map((role) => this.availableRoles[role].displayText),
        };
      });
    },
    async loadRoles() {
      try {
        const response = await this.$http.get(window.CONFIG.uam_api + "/users/roles");
        this.availableRolesArray = response.body;
        this.availableRoles = Object.fromEntries(this.availableRolesArray.map((role) => [role.role, role]));
      } catch (error) {
        this.loading = false;
      }
    },
    async toggleActive(user) {
      try {
        const confirm = await this.$confirm(`Are you sure you want to set ${user.username} ${user.status ? "inactive" : "active"}?`, "Warning", {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        });
        const updatedUser = { ...user, status: !user.status };
        await this.$http.put(`${window.CONFIG.uam_api}/users/${user.id}`, updatedUser);
        this.$message({ message: "Success!", type: "success" });
        this.reloadPage();
      } catch (err) {
        this.$message({ message: "An error has occurred. Please try again later.", type: "error" });
      }
    },
    updatePageNumber(page) {
      this.pagination.page = page;
      this.reloadPage();
    },
    updatePageSize(size) {
      this.pagination.size = size;
      this.pagination.page = 1;
      this.reloadPage();
    },
    async reloadPage() {
      this.cancelEditor();
      this.cancelPasswordReset();
      await this.loadAccounts();
    },
    updateUser(event, username) {
      event.stopPropagation();
      const user = this.accounts.find((account) => account.username === username);
      this.editor.values = { ...user };
      this.editor.title = "Update User";
      this.editor.isCreate = false;
      this.editor.submitText = "Update";
      this.showEditor();
    },
    addUser(event) {
      event.stopPropagation();
      this.editor.values = null;
      this.editor.title = "Add User";
      this.editor.isCreate = true;
      this.editor.submitText = "Save";
      this.showEditor();
    },
    showEditor() {
      this.editor.visible = true;
    },
    cancelEditor() {
      this.editor.visible = false;
    },
    showPasswordReset(userId) {
      this.passwordReset.userId = userId;
      this.passwordReset.visible = true;
    },
    cancelPasswordReset() {
      this.passwordReset.visible = false;
    },
    showError(message) {
      this.$message({ message, type: "error" });
    },
    showSuccess(message) {
      this.$message({ message: "Success!", type: "success" });
      this.reloadPage();
    },
    isUAMAdmin() {
      return auth.userInfo().roles.indexOf("ROLE_UAM_ADMIN") >= 0;
    },
    allowedToEdit() {
      return this.isUAMAdmin() || auth.userInfo().roles.indexOf("UAM_UPDATE") >= 0;
    },
    allowedToRead() {
      return this.isUAMAdmin() || auth.userInfo().roles.indexOf("UAM_READ") >= 0;
    },
    allowedToCreate() {
      return this.isUAMAdmin() || auth.userInfo().roles.indexOf("UAM_CREATE") >= 0;
    },
    allowedToDelete() {
      return this.isUAMAdmin() || auth.userInfo().roles.indexOf("UAM_DELETE") >= 0;
    },
    formatArray(row, column, value) {
      return value.map((e, i, self) => `${e}${i == self.length - 1 ? "" : ","} \n`);
    },
    async exportAccounts() {
      this.accountExport.exporting = true;
      try {
        const columns = ["Username", "Email", "First Name", "Last Name", "Status", ...(this.isMultiFacility ? ["Facility"] : []), "Roles"];
        const body = await this.loadAccountsForExport();
        const accounts = this.generateAccountTableData(body);
        const data = accounts.map((row) => {
          return [row.username, row.email, row.firstName, row.lastName, row.status ? "Active" : "Inactive", ...(this.isMultiFacility ? [row.visibleFacilitiesArray.join(", ")] : []), row.visibleRoles];
        });
        this.createExcelFile("report.xlsx", columns, data);
        this.accountExport.progress = 100;
        this.accountExport.exporting = false;
      } catch (error) {
        this.accountExport.errorDialog = true;
        this.accountExport.exporting = false;
      }
    },
    createExcelFile: function (fileName, columns, data) {
      const detailsRow = util.generateExportDetailsRow();
      data.unshift(columns);
      data.unshift(detailsRow);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    cancelAccountExport() {
      this.accountExport.exporting = false;
      this.accountExport.progress = 0;
    },
  },
  async created() {
    // availableFacilities are the current user's facilities (determines which are shown/interactable on other users)
    this.availableFacilities = Object.fromEntries(this.$configStore.data.sites.map((site) => [site.id, site]));
    this.isMultiFacility = this.$configStore.data.sites?.length > 1;
    // availableRoles are a predefined subset -> fetch these from UAM api
    await this.loadRoles();
  },
  mounted() {
    if (auth.loggedIn() && this.allowedToRead()) {
      this.loadAccounts();
    }
  },
};
</script>
<style scoped>
.accounts-button {
  margin-right: 0.5em;
  margin-top: 1px;
}
.accounts-select {
  margin-right: 0.5em;
  margin-top: 1px;
}
.inactive-user {
  color: red;
}
</style>
