<template>
  <div class="immunizations-tab">
    <immunizations-editor v-if="editor.visible" :title="clientName" :immunization="editor.data" :isBatch="false" :isStaff="isStaff" :clientId="clientId" v-on:immunization-editor-save="closeImmunizationEditor" v-on:immunization-editor-close="closeImmunizationEditor"> </immunizations-editor>

    <table-heading :title="'Immunizations'">
      <template slot="right-alignment">
        <sync-immunizations v-if="canSync" :clientId="clientId" v-on:immunization-sync-complete="getImmunizations()"></sync-immunizations>
        <el-button :disabled="!canModify" v-if="showActions" type="info" @click="addImmunization()">+ Add</el-button>
        <el-pagination
          @size-change="
            (size) => {
              pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="pagination.size"
          :page-count="totalPages"
          layout="sizes, prev, pager, next"
        >
        </el-pagination>
      </template>
    </table-heading>

    <el-table :data="immunizations" v-loading="loading">
      <el-table-column label="Immunization Type" prop="description" width="150"></el-table-column>
      <el-table-column label="Administration Date" width="150">
        <template scope="scope">
          {{ formatDate(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column label="Expiry Date" width="150">
        <template scope="scope">
          {{ formatDate(scope.row.expiry) }}
        </template>
      </el-table-column>
      <el-table-column label="Vaccine Type" prop="vaccineType" width="200"></el-table-column>
      <el-table-column label="Location" prop="location" width="100"></el-table-column>
      <el-table-column label="Lot Number" prop="lotNumber" width="150"></el-table-column>
      <el-table-column label="Declined?" width="100">
        <template scope="scope">
          {{ scope.row.declined ? "Yes" : "No" }}
        </template>
      </el-table-column>
      <el-table-column label="Reason" prop="declinedReason" width="150"></el-table-column>
      <el-table-column label="Notes" prop="note" min-width="300">
        <template scope="scope">
          <div class="linebreak">{{ scope.row.note }}</div>
        </template>
      </el-table-column>

      <el-table-column v-if="showActions" label="" width="100" align="right" fixed="right">
        <template scope="scope">
          <el-button-group>
            <el-button :disabled="!canModify" @click="editImmunization(scope.row)" size="small">
              <Icon :iconKey="'edit'" :description="'Edit'" />
            </el-button>
            <el-button :disabled="!canModify" @click="deleteImmunization(scope.row)" size="small">
              <Icon :iconKey="'delete'" :description="'Delete'" />
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import auth from "../../auth";
import moment from "moment";
import CaseDetailHeading from "../Shared/CaseDetailTableHeading";

import ImmunizationsEditor from "./Editor.vue";
import Icon from "../Shared/Icon";
import SyncImmunizations from "./SyncImmunizationsForClient.vue";

export default {
  name: "immunizations",
  props: ["clientId", "isStaff", "clientName", "showActions"],
  components: {
    "table-heading": CaseDetailHeading,
    "immunizations-editor": ImmunizationsEditor,
    "sync-immunizations": SyncImmunizations,
    Icon,
  },
  watch: {
    "pagination.page": function () {
      this.getImmunizations();
    },
    "pagination.size": function () {
      this.getImmunizations();
    },
  },
  data() {
    return {
      loading: false,
      immunizations: [],
      canSync: false,
      pagination: {
        page: 1,
        size: 5,
      },
      totalPages: 0,
      canModify: false,
      editor: {
        visible: false,
        data: {},
      },
    };
  },
  methods: {
    async closeImmunizationEditor() {
      this.editor.visible = false;
      this.editor.data = {};
      await this.getImmunizations();
    },
    editImmunization(record) {
      this.editor.data = record;
      this.editor.visible = true;
    },
    addImmunization() {
      this.editor.data = {};
      this.editor.visible = true;
    },
    async deleteImmunization(record) {
      await this.$http.delete(`${window.CONFIG.immunization_api}/${record.id}`);
      this.getImmunizations();
    },
    formatDate(timestamp) {
      return timestamp ? moment.utc(timestamp).format(this.$configStore.dateFormat()) : "";
    },
    async getImmunizations() {
      this.loading = true;
      return this.$http
        .get(`${window.CONFIG.immunization_api}/`, {
          params: {
            clientId: this.clientId,
            ...this.pagination,
          },
        })
        .then((resp) => resp.json())
        .then((json) => {
          this.immunizations = json.content;
          this.totalPages = json.totalPages;
        })
        .then(() => (this.loading = false));
    },
  },
  async mounted() {
    /** records can be modified only if:
      1. user has required roles
      2. "showActions" flag enabled (this tab is viewable in several locations, some do not allow editing regardless of other conditions)
      3. adt is disabled (adtEnabled === false) OR it is a staff's records (isStaff === true)
      4. pcc integration is disabled OR it is a staff's records (isStaff === true)
    */
    this.pccIntegrationEnabled = this.$configStore.pccIntegrationEnabled();
    this.canModify = auth.canModify() && this.showActions && (!this.$configStore.adtEnabled() || this.isStaff) && (!this.pccIntegrationEnabled || this.isStaff);
    this.canSync = auth.canModify() && !this.isStaff;
    await this.getImmunizations();
  },
};
</script>
<style scoped>
.linebreak {
  white-space: pre-line;
}
</style>
