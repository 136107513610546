<template>
  <div class="handhygiene">
    <filters-section :showLocation="true" :isHandHygiene="true" :filterDisplayKeys="getFilterKeys()" :showPlaceholderUnits="true" :dateRangeStart="global.defaultDateStart" v-on:change="formatFilters">
      <el-button slot="title" size="small" @click="launch" type="info">Launch Hand Hygiene App</el-button>
      <el-button slot="title" size="small" @click="downloadReport">Download Report</el-button>
    </filters-section>
    <div class="toPrint" ref="toPrint">
    <img v-if="displayLogo" class="logo" alt="HealthConnex" src="/static/logo-dark-transparent.svg" />
    <page-section title="Graph" v-loading="graphSection.loading">
      <template slot="title">
        <div v-if="graphSection.chartType !== 'pie'">
        <span class="label">Group by</span>
        <el-radio-group v-model="graphSection.groupBy" size="small" @change="getSummary">
          <el-radio-button label="Moment"></el-radio-button>
          <el-radio-button label="HCW Type"></el-radio-button>
          <el-radio-button label="Observer"></el-radio-button>
          <el-radio-button label="Unit"></el-radio-button>
          <el-radio-button label="Facility"></el-radio-button>
        </el-radio-group>
        <span v-if="graphSection.chartType !== 'report'" class="label">and</span>
        <el-radio-group v-if="graphSection.chartType !== 'report'" v-model="graphSection.timeScale" size="small" @change="getSummary">
          <el-radio-button label="Week"></el-radio-button>
          <el-radio-button label="Month"></el-radio-button>
          <el-radio-button label="Quarter"></el-radio-button>
          <el-radio-button label="Year"></el-radio-button>
        </el-radio-group>
        </div>
        <span class="label">Chart Type</span>
          <el-select size="mini" v-model="graphSection.chartType" placeholder="stackedColumn" @change="getSummary">
            <el-option label="Stacked Column Chart" value="stackedColumn"> </el-option>
            <el-option label="Pie Chart" value="pie"> </el-option>
            <el-option label="Bar Chart" value="bar"> </el-option>
            <el-option label="Column Chart" value="column"> </el-option>
            <!-- <el-option label="Line Chart" value="line"> </el-option> -->
            <el-option label="Report" value="report"> </el-option>
          </el-select>
      </template>
      <div class="highchart-graphs" v-if="graphSection.chartType !== 'report'">
      <stacked-column-chart v-if="graphSection.chartType === 'stackedColumn'" :title="graphSection.title" :series="graphSection.series" :subtitle="graphSection.subtitle" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxisTitle" :yAxisPlotLines="graphSection.plotLines" :usePerColumnLabels="true"></stacked-column-chart>
      <pie-chart v-if="graphSection.chartType === 'pie'" :title="graphSection.title" :series="graphSection.series"></pie-chart>
      <bar-chart v-if="graphSection.chartType === 'bar'" :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxisTitle"></bar-chart>
      <column-chart v-if="graphSection.chartType === 'column'" :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxisTitle"></column-chart>
      <!-- <line-chart v-if="graphSection.chartType === 'line'" :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxisTitle"></line-chart> -->
      </div>
      <el-table v-if="graphSection.chartType === 'report'" :data="summarySection.data">
        <el-table-column prop="label" :label="graphSection.groupBy"></el-table-column>
          <el-table-column label="Compliance">
            <template scope="scope">
              <div class="progress-border">
                <div class="progress-fill" :style="{ width: (summarySection.showTrueCompliance ? scope.row.totals.percentTrueCompliance : scope.row.totals.percent) + '%' }" :percentage="(summarySection.showTrueCompliance ? scope.row.totals.percentTrueCompliance : scope.row.totals.percent) * 100"></div>
              </div>
              <!-- <sparkline-chart :series="[generateSparklineChartData(scope.row)]" :xAxisCategories="graphSection.xAxis"></sparkline-chart> -->
            </template>            
          </el-table-column>
          <el-table-column label="%">
            <template scope="scope">
                <div v-html="summarySection.showTrueCompliance ? scope.row.totals.percentTrueCompliance : scope.row.totals.percent"></div>
            </template>
          </el-table-column>
        <el-table-column label="Complied / Total">
          <template scope="scope">
            <div v-html="displayComplianceTotalFraction(scope.row.totals)"></div>
          </template>
        </el-table-column>
      </el-table>
    </page-section>
    <page-section title="Summary" v-loading="summarySection.loading">
      <template slot="title">
        <span class="complianceToggle">
          <span>Show strict compliance</span>
          <el-switch v-model="summarySection.showTrueCompliance" v-on:change="changeComplianceCalculation" on-color="#13ce66" on-text="" off-text=""> </el-switch>
        </span>
        <el-button size="small" class="do-not-print" @click="exportSummaryToExcel">Export Data</el-button>
      </template>
      <el-table :data="summarySection.data">
        <el-table-column prop="label" :label="graphSection.groupBy"></el-table-column>
        <template v-for="colKey in summarySection.columns">
          <el-table-column :key="colKey" :label="colKey">
            <template scope="scope">
              <div v-html="renderCompliance(scope.row[colKey])"></div>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="Total">
          <template scope="scope">
            <div v-html="renderCompliance(scope.row.totals)"></div>
          </template>
        </el-table-column>
      </el-table>
    </page-section>
    </div>
    <page-section class="do-not-print" :title="`Observations (${detailsSection.pagination.total})`" v-loading="detailsSection.loading">
      <template slot="title">
        <el-button size="small" :disabled="isReadOnly()" @click="createObservation()">+ New Observation</el-button>
        <el-button size="small" @click="exportObservationsToExcel()">Export Data</el-button>
        <el-pagination
          @size-change="
            (size) => {
              detailsSection.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              detailsSection.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="detailsSection.pagination.size"
          layout="sizes, prev, pager, next"
          :total="detailsSection.pagination.total"
        >
        </el-pagination>
      </template>
      <el-table :data="detailsSection.data" @row-click="openObservationEditor">
        <el-table-column prop="observerName" label="Observer" width="200" fixed> </el-table-column>
        <el-table-column prop="facility" label="Facility" width="120"> </el-table-column>
        <el-table-column prop="unitName" label="Unit" width="120"> </el-table-column>
        <el-table-column prop="collectionDateFormatted" label="Collection Date" width="150"> </el-table-column>
        <el-table-column prop="workerType" label="HCW Type" width="120"> </el-table-column>
        <el-table-column prop="moment" label="Moment" width="140"> </el-table-column>
        <el-table-column prop="outcome" label="Outcome" width="175">
          <template scope="scope">
            <span :class="normalizeOutcome(scope.row.outcome)" class="outcome"></span>
            {{ scope.row.outcome.outcome === "Missed" ? "Missed" : `${scope.row.outcome.outcome} (${scope.row.outcome.seconds} sec.)` }}
          </template>
        </el-table-column>
        <template v-for="customType in global.settings.customTypes">
          <el-table-column :key="customType" v-bind:label="customType" width="100">
            <template scope="scope">
              {{ scope.row.customTypes[customType] ? "Yes" : "No" }}
            </template>
          </el-table-column>
        </template>
        <el-table-column prop="createdBy" label="Created By" width="120"> </el-table-column>
        <el-table-column prop="createdDateFormatted" label="Created Date" width="150"> </el-table-column>
        <el-table-column label="Comments" min-width="300">
          <template scope="scope">
            <div class="comments">{{ scope.row.comments }}</div>
          </template>
        </el-table-column>
        <el-table-column width="120" fixed="right">
          <template scope="scope">
            <el-button v-if="scope.row.active" :disabled="isReadOnly()" @click="copyObservation($event, scope.row)" size="mini">Copy</el-button>
            <el-button v-if="scope.row.active" :disabled="isReadOnly()" @click="deleteObservation($event, scope.row.id)" size="mini" icon="delete"></el-button>
            <span v-if="scope.row.active === false"> Deleted case </span>
          </template>
        </el-table-column>
      </el-table>
    </page-section>
    <Editor v-if="detailsSection.editObservationDialogisVisible" :title="detailsSection.observationTitle" :values="detailsSection.editFormValues" v-on:editor-close="cancelObservationEditor" v-on:editor-save="reloadObservations"></Editor>
    <progress-overlay v-if="detailsSection.exportingObservations" :progress="detailsSection.observationExportProgress" v-on:progress-cancelled="cancelObservationExport" title="Download In Progress"></progress-overlay>

    <el-dialog title="Error, please try again" v-model="detailsSection.loadingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error loading Observations. Please click refresh to try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            getObservations();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="Error, please try again" v-model="detailsSection.exportingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error exporting Observations. Please try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            exportObservationsToExcel();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import auth from "../../auth";
import util from "../../util";
import moment from "moment";
import XLSX from "xlsx";
import Editor from "./Editor";
import ProgressOverlay from "../../components/ProgressBarOverlay";
import FiltersSection from "../Shared/FiltersSection";
import PageSection from "../Shared/PageSection";
import StackedColumnChart from "../Shared/StackedColumnChart";
import PieChart from "../Shared/PieChart";
import BarChart from "../Shared/BarChart";
import ColumnChart from "../Shared/ColumnChart";
import LineChart from "../Shared/LineChart";
import SparklineChart from "../Shared/SparklineChart.vue";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export default {
  name: "ppe-page",
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    "progress-overlay": ProgressOverlay,
    "stacked-column-chart": StackedColumnChart,
    "pie-chart": PieChart,
    "bar-chart": BarChart,
    "column-chart": ColumnChart,
    "line-chart": LineChart,
    "sparkline-chart": SparklineChart,
    Editor
  },
  watch: {
    "detailsSection.pagination.size"() {
      this.getObservations();
    },
    "detailsSection.pagination.page"() {
      this.getObservations();
    },
  },
  data() {
    return {
      global: {
        settings: {},
        filters: {},
        defaultDateStart: moment().subtract(7, "days").toDate(),
        sites: [],
        audits: [],
        momentGroups: [],
        criteria: {},
        facilities: [],
        groupBy: {
          Moment: "moment",
          "HCW Type": "workerType",
          Unit: "unit",
          Facility: "facility",
          Observer: "observer",
        },
      },
      summarySection: {
        loading: true,
        data: [],
        showTrueCompliance: false,
        exportModels: {},
      },
      detailsSection: {
        loading: true,
        editFormValues: {},
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        editObservationDialogisVisible: false,
        exportingObservations: false,
        observationExportProgress: 0,
        observationTitle: "",
        loadingErrorDialog: false,
        exportingErrorDialog: false,
        data: [],
      },
      graphSection: {
        loading: true,
        groupBy: "Moment",
        timeScale: "Week",
        series: [],
        title: "Hand Hygiene Compliance",
        subtitle: "",
        yAxisTitle: "% Compliance",
        xAxis: [],
        plotLines: [],
        chartType: "stackedColumn",
      },
      displayLogo: false,
    };
  },
  methods: {
    launch() {
      open(`${window.CONFIG.ipac_web_host}${window.CONFIG.ipac_web_port}/ipac/hh`, "_blank");
    },
    async downloadReport() {
      this.displayLogo = true;
      await this.$nextTick();
      this.displayLogo = false;
      const canvas = await html2canvas(this.$refs.toPrint);
      const data = canvas.toDataURL('image/png');
      const padding = 10;
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width + 2 * padding, canvas.height + 2 * padding]
      })
      pdf.addImage(data, 'PNG', padding, padding, canvas.width, canvas.height);
      pdf.save('report.pdf');
    },
    getFilterKeys() {
      let filters = ["collectionDate", "moments", "hcwType", "observers", "outcomes", "compliance", "showDeleted"];
      return filters;
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "hcwType":
                k = "workerTypes";
                break;
              case "units":
                k = "unitIds";
                break;
              case "facilities":
                k = "facilityIds";
                break;
              case "showDeleted":
                if (v === "active" || v === "deleted") {
                  k = "active";
                  v = v === "active";
                }
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      this.global.filters.start = moment(filterValues.collectionDate[0]).startOf("day").toDate().toString();
      this.global.filters.end = moment(filterValues.collectionDate[1]).endOf("day").toDate().toString();
      // if (filterValues.observers.length === 0) delete this.global.filters.observers
      delete this.global.filters.collectionDate;
      delete this.global.filters.showDeleted;
      this.refreshAllData();
    },
    dateToTimeScale(dateObj) {
      switch (this.graphSection.timeScale) {
        case "Week":
          return `W${moment(dateObj).format("WW-YYYY")}`;
        case "Month":
          return `${moment(dateObj).format("YYYY-MM")}`;
        case "Quarter":
          return `Q${moment(dateObj).format("Q-YYYY")}`;
        default:
          return `${moment(dateObj).format("YYYY")}`;
      }
    },
    changeComplianceCalculation(toggle) {
      this.summarySection.showTrueCompliance = toggle;
      this.getSummary();
    },
    getMomentsFromObservation(observation) {
      const timestamp = this.dateToTimeScale(Date.parse(observation.modifiedTimestamp));
      const moments = { timestamp };
      observation.data.sections.forEach((s) => {
        const moment = s.name;
        moments[moment] = {};
        s.questions.forEach((q) => {
          if (q.response && q.response.label) {
            const answer = q.response.label;
            moments[moment][answer] = moments[moment][answer] || 0;
            moments[moment][answer] += 1;
          }
        });
      });
      return moments;
    },
    generateStackedColumnChartData(groupedTotals, timePeriods) {
      const colors = {
        missed: "#ff5050",
        complete: "#00D560",
        incomplete: "#ffcc00",
        placeholder: "#cccccc",
      };
      const generateSeries = (group, periods, seriesName) => {
        return {
          name: `${group} - ${seriesName}`,
          stack: `${group}`,
          color: colors[seriesName],
          data: timePeriods.map((period) => {
            const value = Math.round((periods[period][seriesName] / periods[period].total) * 100);
            const normalized = Number.isNaN(value) ? 0 : value;
            return {
              y: normalized,
              dataLabels: {
                enabled: normalized > 0,
                format: "{y}%",
              },
            };
          }),
        };
      };
      let orderedTotals = [...groupedTotals.entries()];
      this.graphSection.xAxis = timePeriods;

      this.graphSection.subtitle =
      `
        <svg width="10" height="10"><circle cx="5" cy="5" r="5" fill="${colors.complete}" /></svg><span style="margin-left:5px; margin-right: 15px;">Compliant</span>
        <svg width="10" height="10"><circle cx="5" cy="5" r="5" fill="${colors.incomplete}" /></svg><span style="margin-left:5px; margin-right: 15px;">Non-Compliant</span>
        <svg width="10" height="10"><circle cx="5" cy="5" r="5" fill="${colors.missed}" /></svg><span style="margin-left:5px; margin-right: 15px;">Missed</span>
      `;
      
      this.graphSection.series = orderedTotals.flatMap(([group, periods]) => {
        return [
          {
            name: `${group} - placeholder`,
            stack: `${group}`,
            color: colors.placeholder,
            data: timePeriods.map((period) => {
              return periods[period].total === 0 ? {
                y: 100,
                className: "placeholder",
                dataLabels: {
                  enabled: false,
                },
              } : null;
            }),
          },
          generateSeries(group, periods, "missed"),
          generateSeries(group, periods, "incomplete"),
          generateSeries(group, periods, "complete"),
        ];
      });
    },
    generatePieChartData(summary) {
      const colors = {
        missed: "#ff5050",
        complete: "#00D560",
        incomplete: "#ffcc00",
        placeholder: "#cccccc",
      };

      this.graphSection.series = [];
      const complianceFrequency = summary.reduce((acc, value) => {
        acc[value.status] = (acc[value.status] || 0) + value.total;
        return acc;
      }, {});
      for (const complianceType in complianceFrequency) {
        this.graphSection.series.push({
          name: complianceType,
          y: complianceFrequency[complianceType],
          color: colors[complianceType]
        });
      }
    },
    generateBarChartData(groupedTotals, timePeriods) {
      const colors = [
        "#7fdfbf",
        "#ff0000",
        "#4eab77",
        "#5959c0",
        "#8b0000",
        "#b0b000",
        "#b03060",
        "#ff8c00",
        "#a6c6da",
        "#7fff00",
        "#60bfdf",
        "#ba55d3",
        "#0000ff",
        "#ff00ff",
        "#1e90ff",
        "#eee8aa",
        "#87cefa",
        "#ffa07a",
        "#98fb98",
        "#dda0dd",
      ];
      this.graphSection.xAxis = [...groupedTotals.keys()];
      this.graphSection.series = timePeriods.map((value, index) => {
        return {
          name: value,
          data: [],
          color: colors[index % colors.length],
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y + '%';
            },
            allowOverlap: true
          },
        }
      });
      groupedTotals.forEach(category => {
        let counter = 0;
        for (const timePeriod in category) {
          if (category[timePeriod].total > 0) {
            this.graphSection.series[counter].data.push(this.summarySection.showTrueCompliance ? category[timePeriod].percentTrueCompliance : category[timePeriod].percent);
          } else {
            this.graphSection.series[counter].data.push(null);
          }
          counter++;
        }
      })
    },
    generateLineChartData(groupedTotals, timePeriods) {
      this.graphSection.xAxis = timePeriods;
      const series = [];
      groupedTotals.forEach((value, key) => {
        const complianceOverTime = [];
        for (const timePeriod in value) {
          complianceOverTime.push(this.summarySection.showTrueCompliance ? value[timePeriod].percentTrueCompliance : value[timePeriod].percent);
        }
        series.push({
          name: key,
          data: complianceOverTime
        });
      });
      this.graphSection.series = series;
    },
    generateSparklineChartData(category) {
      const data = [];
      for (let timePeriod in category) {
        if (timePeriod !== "label" && timePeriod !== "totals") {
          data.push(category[timePeriod].complete);
        }
      }
      return {
        name: category.label,
        data: data,
      }
    },
    generateSummaryTableData(groupedTotals, timePeriods) {
      this.summarySection.data = [];
      this.summarySection.columns = [...timePeriods];
      const orderedTotals = [...groupedTotals.entries()];
      const percentage = (float) => Math.round(float * 1000) / 10;

      const totals = {
        label: "Total",
        totals: {
          missed: 0,
          incomplete: 0,
          complete: 0,
          total: 0,
          percent: 0,
          percentTrueCompliance: 0,
        },
      };
      orderedTotals.forEach(([groupLabel, value]) => {
        const row = {
          label: groupLabel,
          ...value,
          totals: {
            missed: 0,
            incomplete: 0,
            complete: 0,
            total: 0,
            percent: 0,
            percentTrueCompliance: 0,
          },
        };
        this.summarySection.columns.forEach((colKey) => {
          row[colKey].total = row[colKey].complete + row[colKey].incomplete + row[colKey].missed;
          row[colKey].percent = row[colKey].total === 0 ? 0 : percentage((row[colKey].complete + row[colKey].incomplete) / row[colKey].total);
          row[colKey].percentTrueCompliance = row[colKey].total === 0 ? 0 : percentage(row[colKey].complete / row[colKey].total);

          row.totals.complete += row[colKey].complete;
          row.totals.incomplete += row[colKey].incomplete;
          row.totals.total += row[colKey].total;
          row.totals.percent = row.totals.total === 0 ? 0 : percentage((row.totals.complete + row.totals.incomplete) / row.totals.total);
          row.totals.percentTrueCompliance = row.totals.total === 0 ? 0 : percentage(row.totals.complete / row.totals.total);

          totals[colKey] = totals[colKey] || {
            missed: 0,
            incomplete: 0,
            complete: 0,
            total: 0,
            percent: 0,
            percentTrueCompliance: 0,
          };
          totals[colKey].missed += row[colKey].missed;
          totals[colKey].incomplete += row[colKey].incomplete;
          totals[colKey].complete += row[colKey].complete;
          totals[colKey].total += row[colKey].total;
          totals[colKey].percent = totals[colKey].total === 0 ? 0 : percentage((totals[colKey].complete + totals[colKey].incomplete) / totals[colKey].total);
          totals[colKey].percentTrueCompliance = totals[colKey].total === 0 ? 0 : percentage(totals[colKey].complete / totals[colKey].total);

          totals.totals = totals.totals || {
            missed: 0,
            incomplete: 0,
            complete: 0,
            total: 0,
            percent: 0,
            percentTrueCompliance: 0,
          };
          totals.totals.incomplete += row[colKey].incomplete;
          totals.totals.complete += row[colKey].complete;
          totals.totals.missed += row[colKey].missed;
          totals.totals.total += row[colKey].total;
          totals.totals.percent = totals.totals.total === 0 ? 0 : percentage((totals.totals.complete + totals.totals.incomplete) / totals.totals.total);
          totals.totals.percentTrueCompliance = totals.totals.total === 0 ? 0 : percentage(totals.totals.complete / totals.totals.total);
        });
        this.summarySection.data.push(row);
      });
      this.summarySection.data.push(totals);
    },
    generateObservationsTableData(record) {
      let model = { ...record };
      model.collectionDateFormatted = moment(record.collectionDate).format(`${this.$configStore.dateFormat()} HH:mm`);
      model.createdDateFormatted = moment(record.created).format(`${this.$configStore.dateFormat()} HH:mm`);
      model.facility = this.global.facilities.filter((s) => s.id === Number(record.facId))[0].displayText;
      model.customTypes = {};
      this.global.settings.customTypes.forEach((type) => {
        model.customTypes[type] = record.customTypes === null ? false : (record.customTypes.filter((t) => t.type === type)[0] || {}).checked || false;
      });
      return model;
    },
    createSummary(summary) {
      const timeScale = this.graphSection.timeScale;
      const formatTime = {
        Week: (record) => `W${record.dategroup}-${record.year}`,
        Month: (record) => `${record.year}-${`${record.dategroup}`.padStart(2, "0")}`,
        Quarter: (record) => `Q${record.dategroup}-${record.year}`,
        Year: (record) => `${record.year}`,
      };

      const allTimePeriods = new Map();
      const groupKey = this.global.groupBy[this.graphSection.groupBy];
      const convertAndGroup = (record) => {
        const row = {
          time: formatTime[timeScale](record),
          group: record[groupKey],
          count: { [record.status]: record.total },
          data: record,
        };
        allTimePeriods.set(row.time, { complete: 0, incomplete: 0, missed: 0 });
        return row;
      };
      const orderedCounts = summary.map(convertAndGroup);
      const getSummaryDefaults = () => JSON.parse(JSON.stringify(Object.fromEntries(allTimePeriods)));

      let groupedTotals = new Map();
      if (this.graphSection.groupBy === "Moment") {
        groupedTotals = new Map(this.global.momentGroups.map((group) => [group, getSummaryDefaults()]));
      }

      orderedCounts.forEach((row) => {
        if (!groupedTotals.has(row.group)) {
          groupedTotals.set(row.group, getSummaryDefaults());
        }
        let values = groupedTotals.get(row.group);
        values[row.time] = values[row.time] || {
          complete: 0,
          incomplete: 0,
          missed: 0,
        };
        values[row.time].complete += row.count.complete || 0;
        values[row.time].incomplete += row.count.incomplete || 0;
        values[row.time].missed += row.count.missed || 0;
        groupedTotals.set(row.group, values);
      });
      return [groupedTotals, [...new Set(orderedCounts.map((c) => c.time))]];
    },
    exportSummaryToExcel() {
      this.summarySection.loading = true;
      const columns = [this.graphSection.groupBy, this.graphSection.timeScale, "Compliant", "Non-Compliant", "Missed", `Compliance Percentage`, `Strict Compliance Percentage`, "Total Observations"];
      let sortedGroups = [...this.summarySection.exportModels.groups.entries()];
      const data = this.summarySection.exportModels.periods.flatMap((period) => {
        return sortedGroups.map(([group, timePeriods]) => {
          return [group, period, timePeriods[period].complete, timePeriods[period].incomplete, timePeriods[period].missed, `${timePeriods[period].percent}%`, `${timePeriods[period].percentTrueCompliance}%`, timePeriods[period].total];
        });
      });
      const t = [...this.summarySection.data].slice(-1)[0].totals;
      data.push(["Totals", "", t.complete, t.incomplete, t.missed, `${t.percent}%`, `${t.percentTrueCompliance}%`, t.total]);
      this.createExcelFile("observations.xlsx", columns, data);
      this.summarySection.loading = false;
    },
    async exportObservationsToExcel() {
      const _this = this;
      this.detailsSection.exportingObservations = true;
      this.detailsSection.observationExportProgress = 0;
      let pageProgress = 0;
      let hasMorePages = true;
      const customTypes = this.global.settings.customTypes;
      const size = 1000;
      const hhOutcomeCriteria = this.$configStore.hhOutcomeCriteria();
      const columns = ["ID", "Observer", "Facility", "Unit", "Collection Date", "Collection Time", "HCW Type", "Moment", "Outcome", "Compliance", "Duration", ...customTypes, "Active", "Created By", "Created Date", "Updated By", "Updated Date", "Comments"];

      const convertDataToColumns = (json) => {
        return [
          json.id,
          json.observerName,
          _this.global.facilities.filter((f) => f.id === json.facId)[0].displayText,
          json.unitName,
          moment(json.collectionDate).format(this.$configStore.dateFormat()),
          moment(json.collectionDate).format("HH:mm"),
          json.workerType,
          json.moment,
          json.outcome.outcome,
          _this.isValidOutcome(json.outcome) ? `Compliant` : `Not Compliant`,
          json.outcome.seconds,
          ...customTypes.map((type) =>
            (json.customTypes || [])
              .filter((c) => c.checked)
              .map((c) => c.type)
              .includes(type)
          ),
          json.active,
          json.createdBy,
          moment(json.created).format(this.$configStore.dateFormat()),
          json.updatedBy,
          moment(json.updated).format(this.$configStore.dateFormat()),
          json.comments || "",
        ];
      };

      const getPageOfData = async (page) => {
        return await this.$http
          .get(`${window.CONFIG.hh_api}/observations`, {
            params: {
              ...this.global.filters,
              hhOutcomeCriteria,
              page,
              size,
            },
          })
          .then((response) => response.body);
      };
      let data = [];
      while (hasMorePages) {
        this.detailsSection.exportingErrorDialog = false;
        if (!this.detailsSection.exportingObservations) {
          this.cancelObservationExport();
          break;
        }
        const json = await getPageOfData(pageProgress + 1).catch((err) => {
          if (err.status === 0) {
            this.cancelObservationExport();
            this.detailsSection.exportingErrorDialog = true;
          }
        });
        this.detailsSection.observationExportProgress = Math.round(((pageProgress + 1) / json.totalPages) * 100);
        if (json.totalPages > pageProgress + 1) {
          pageProgress++;
        } else {
          hasMorePages = false;
        }
        data.push(json.content);
      }
      if (!this.detailsSection.exportingObservations) {
        return;
      }
      data = data.flat().map(convertDataToColumns);
      this.createExcelFile("report.xlsx", columns, data);
      this.detailsSection.exportingObservations = false;
      this.detailsSection.exportingErrorDialog = false;
    },
    createExcelFile: function (fileName, columns, data) {
      const detailsRow = util.generateExportDetailsRow();
      data.unshift(columns);
      data.unshift(detailsRow);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    cancelObservationExport() {
      this.detailsSection.exportingObservations = false;
      this.detailsSection.observationExportProgress = 0;
    },
    async deleteObservation(event, id) {
      event.stopPropagation();
      this.$confirm("Delete this record?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          this.detailsSection.loading = true;
          await this.$http.delete(window.CONFIG.api + "/handhygiene/observations/" + id);
          this.refreshAllData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    copyObservation(event, record) {
      event.stopPropagation();
      this.createObservation(record);
    },
    openObservationEditor(record) {
      if (this.isReadOnly() || record.active === false) {
        return;
      }
      // TODO hhAdmin only
      this.detailsSection.observationTitle = "Edit Observation";
      this.detailsSection.editFormValues = { ...record };
      this.detailsSection.editObservationDialogisVisible = true;
    },
    cancelObservationEditor() {
      this.detailsSection.editFormValues = null;
      this.detailsSection.editObservationDialogisVisible = false;
    },
    createObservation(record) {
      this.detailsSection.observationTitle = "Create Observation";
      this.detailsSection.editFormValues = null;
      if (record) {
        this.detailsSection.editFormValues = { ...record };
        this.detailsSection.editFormValues.id = null;
      }
      this.detailsSection.editObservationDialogisVisible = true;
    },
    reloadObservations() {
      this.detailsSection.editFormValues = null;
      this.detailsSection.editObservationDialogisVisible = false;
      this.refreshAllData();
    },
    async getObservations() {
      this.detailsSection.loading = true;
      const json = await this.$http
        .get(`${window.CONFIG.hh_api}/observations`, {
          params: {
            ...this.global.filters,
            hhOutcomeCriteria: this.$configStore.hhOutcomeCriteria(),
            page: this.detailsSection.pagination.page,
            size: this.detailsSection.pagination.size,
          },
        })
        .then((response) => response.body)
        .catch((err) => {
          if (err.status === 0) {
            this.detailsSection.loadingErrorDialog = true;
          }
          this.detailsSection.loading = false;
          return {
            page: 0,
            content: [],
            totalElements: 0,
          };
        });
      this.detailsSection.pagination.total = json.totalElements;
      this.detailsSection.data = json.content.map(this.generateObservationsTableData);
      this.detailsSection.loading = false;
    },
    async getSummary() {
      this.graphSection.loading = true;
      this.summarySection.loading = true;
      const summary = await this.$http
        .get(`${window.CONFIG.hh_api}/summary`, {
          params: {
            ...this.global.filters,
            hhOutcomeCriteria: this.$configStore.hhOutcomeCriteria(),
            groupBy: this.global.groupBy[this.graphSection.groupBy],
            timeScale: this.graphSection.timeScale.toLowerCase(),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((response) => response.body)
        .catch(() => {
          this.summarySection.loading = false;
          this.graphSection.loading = false;
          return [];
        });
      const [groups, periods] = this.createSummary(summary);
      this.summarySection.exportModels = { groups, periods };
      this.generateSummaryTableData(groups, periods);

      switch(this.graphSection.chartType) {
        case 'stackedColumn':
          this.generateStackedColumnChartData(groups, periods);
          break;
        case 'pie':
          this.generatePieChartData(summary);
          break;
        case 'bar':
          this.generateBarChartData(groups, periods);
          break;
        case 'column':
          this.generateBarChartData(groups, periods);
          break;
        case 'line':
          this.generateLineChartData(groups, periods);
          break;
        case 'sparkline':
          this.graphSection.xAxis = periods;
          break;
      }
      this.graphSection.loading = false;
      this.summarySection.loading = false;
    },
    async refreshAllData() {
      this.getObservations();
      this.getSummary();
    },
    isValidOutcome(data) {
      const criteria = this.global.criteria;
      if (criteria[data.outcome] !== undefined && data.seconds >= criteria[data.outcome]) {
        return true;
      } else {
        return false;
      }
    },
    normalizeOutcome(data) {
      if (data.outcome === "Missed") {
        return "missed";
      }
      if (this.isValidOutcome(data)) {
        return "complete";
      }
      return "incomplete";
    },
    isHHAdmin: function () {
      return auth.userInfo().roles.indexOf("ROLE_HH_ADMIN") >= 0;
    },
    isWebObserver: function () {
      return auth.userInfo().roles.indexOf("ROLE_HH_WEB_OBSERVER") >= 0;
    },
    isReadOnly: function () {
      return auth.userInfo().roles.indexOf("ROLE_READ_ONLY_USER") >= 0;
    },
    allowedToEdit: function () {
      return this.isHHAdmin() || this.isWebObserver();
    },
    renderCompliance(record) {
      const perc = this.summarySection.showTrueCompliance ? record.percentTrueCompliance : record.percent;
      if (record.total === 0) {
        return "--";
      }
      return `${perc}% <span class="bracket">(${this.displayComplianceTotalFraction(record)})</span>`;
    },
    displayComplianceTotalFraction(record) {
      const numerator = record.complete + (this.summarySection.showTrueCompliance ? 0 : record.incomplete);
      const denominator = record.total;
      return `${numerator}/${denominator}`;
    }
  },
  async created() {
    console.clear();
    // TODO: remove facId=1 ?
    const handHygienSettings = await this.$http.get(window.CONFIG.api + "/handhygiene/settings?facId=1").then((response) => response.body);
    this.global.settings = handHygienSettings;
    this.global.criteria = this.$configStore.hhOutcomeCriteria();
    this.global.facilities = this.$configStore.data.sites;
    this.global.momentGroups = handHygienSettings.moments;
    const plotLine = (value, color) => ({
      value: value,
      color: color,
      dashStyle: "shortdash",
      width: 2,
      zIndex: 5,
      label: {
        align: "right",
        text: `${value}%`,
        x: -10,
      },
    });
    const threshold = Number(this.$configStore.hhTarget());
    const belowThreshold = Number(this.$configStore.hhBelowTarget());
    if (threshold !== 0) {
      this.graphSection.plotLines.push(plotLine(threshold, "blue"));
    }
    if (belowThreshold !== 0) {
      this.graphSection.plotLines.push(plotLine(belowThreshold, "red"));
    }
  },
};
</script>

<style>
.handhygiene {
  --status-missed: #ff5050;
  --status-complete: #00d560;
  --status-incomplete: #ffcc00;
}
.handhygiene .outcome {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: var(--status-missed);
}
.handhygiene .complete {
  background-color: var(--status-complete);
}
.handhygiene .incomplete {
  background-color: var(--status-incomplete);
}
.handhygiene .complianceToggle {
  margin-right: 10px;
}
.handhygiene .comments {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.handhygiene .bracket {
  color: #a7a7a7;
}
.handhygiene .progress-border {
  height: 36px;
  width: 60%;
  position: relative;
  box-shadow: inset 0px 0px 0px 1px #b7b7b7;
  border-radius: 10px;
  overflow: hidden;
  background: #b7b7b7;
  margin-top: 10px;
  margin-bottom: 10px;
}
.handhygiene .progress-fill {
  background: #659DB7;
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  left: 0;
  transition: 0.25s width linear;
  color: white;
  text-align: right;
}
.handhygiene .highchart-graphs {
  margin: 10px 10px 0 10px;
}
.handhygiene .logo {
  width: 200px;
  height: 100%;
}
</style>
