<template>
  <div class="infection-rates">
    <filters-section :showLocation="true" :showCurrentLocationToggle="true" :filterDisplayKeys="getFilterKeys()" :moreFilters="global.additionalFilters" :dateRangeStart="global.defaultDateRange[0]" :dateRangeEnd="global.defaultDateRange[1]" v-on:change="formatFilters"> </filters-section>

    <page-section title="Graph" v-loading="graphSection.loading">
      <template slot="title">
        <span class="label">Group by</span>
        <el-radio-group v-model="graphSection.groupBy" size="small" @change="getSummary">
          <el-radio-button label="Infection Site"></el-radio-button>
          <el-radio-button label="Infection Site Type"></el-radio-button>
          <el-radio-button label="Primary Organism"></el-radio-button>
          <!-- <el-radio-button label="Medication"></el-radio-button> -->
          <el-radio-button label="Individual Type"></el-radio-button>
          <el-radio-button label="Unit"></el-radio-button>
          <el-radio-button label="Facility"></el-radio-button>
        </el-radio-group>
        <span class="label">and</span>
        <el-radio-group v-model="graphSection.timeScale" size="small" @change="getSummary">
          <el-radio-button label="Month"></el-radio-button>
          <el-radio-button label="Quarter"></el-radio-button>
          <el-radio-button label="Year"></el-radio-button>
        </el-radio-group>
        <span class="label">Chart Type</span>
        <el-select size="mini" v-model="graphSection.chartType" placeholder="stackedColumn" @change="getSummary">
          <el-option label="Column Chart" value="stackedColumn"> </el-option>
          <el-option label="Line Chart" value="line"> </el-option>
        </el-select>
      </template>
      <div style="margin: 10px">
        <div class="chart-title">Infection Rates</div>
        <stacked-column-chart v-if="graphSection.chartType === 'stackedColumn'" :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxis" :yAxisPlotLines="graphSection.plotLines" :usePerColumnLabels="true" :configuration="graphSection.customizations"></stacked-column-chart>
        <line-chart v-if="graphSection.chartType === 'line'" :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxis" />
      </div>
    </page-section>

    <page-section title="Summary" v-loading="summarySection.loading">
      <template slot="title">
        <el-button size="small" type="warning" @click="editDaysOfTherapy" class="dotButton">Manage Resident Days</el-button>
        <el-button size="small" @click="exportSummaryToExcel">Export Data</el-button>
      </template>
      <el-table :data="summarySection.data">
        <el-table-column prop="label" :label="graphSection.groupBy"></el-table-column>
        <template v-for="colKey in summarySection.columns">
          <el-table-column :key="colKey" :label="colKey">
            <template scope="scope">
              {{ scope.row[colKey] ? scope.row[colKey].count : "--" }}
            </template>
          </el-table-column>
        </template>
        <el-table-column label="Total">
          <template scope="scope">
            {{ scope.row.summationColumn }}
          </template>
        </el-table-column>
      </el-table>
    </page-section>

    <page-section :title="`Analysis Details (${detailsSection.pagination.total})`" v-loading="!detailsTableReady">
      <template slot="title">
        <el-button size="small" @click="exportObservationsToExcel()">Export Data</el-button>
        <el-pagination
          @size-change="
            (size) => {
              detailsSection.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              detailsSection.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="detailsSection.pagination.size"
          layout="sizes, prev, pager, next"
          :total="detailsSection.pagination.total"
        >
        </el-pagination>
      </template>
      <el-table :data="detailsSection.data">
        <el-table-column label="Name" width="150" fixed>
          <template scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
            ({{ scope.row.client.staffId || scope.row.client.patientId }})
          </template>
        </el-table-column>
        <el-table-column prop="facility" label="Facility" width="200" v-if="$configStore.data.sites.length > 1"> </el-table-column>
        <el-table-column prop="location" label="Onset Location" width="175"> </el-table-column>
        <el-table-column prop="infection" label="Infection Site/Type" width="200"> </el-table-column>
        <el-table-column prop="status" label="Status" width="150"> </el-table-column>
        <el-table-column label="Symptoms" width="200">
          <template scope="scope">
            <el-tag v-for="s in scope.row.symptoms" :key="s.displayText" :type="s.tagType">{{ s.displayText }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Outcome" width="200">
          <template scope="scope">
            <div>{{ scope.row.outcome }}{{ scope.row.outcomeSubType ? `/ ${scope.row.outcomeSubType}` : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="primaryOrganism" label="Primary Organism" width="200"> </el-table-column>
        <el-table-column prop="treatment" label="Treatment" width="200"> </el-table-column>
        <el-table-column prop="onsetDateFormatted" label="Onset Date" min-width="100"> </el-table-column>
      </el-table>
    </page-section>

    <progress-overlay v-if="detailsSection.exportingObservations" :progress="detailsSection.observationExportProgress" v-on:progress-cancelled="cancelObservationExport" title="Download In Progress"></progress-overlay>

    <el-dialog title="Error, please try again" v-model="detailsSection.loadingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error loading Observations. Please click refresh to try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            getDetails();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="Error, please try again" v-model="detailsSection.exportingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error exporting Observations. Please try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            exportObservationsToExcel();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>

    <DotEditor v-if="global.showDaysOfTherapy" v-on:hide="hideDaysOfTherapy" v-on:refresh="hideDaysOfTherapy"></DotEditor>
  </div>
</template>

<script>
import moment from "moment";
import XLSX from "xlsx";
import util from "../../../util";
import ProgressOverlay from "../../../components/ProgressBarOverlay";
import FiltersSection from "../../Shared/FiltersSection";
import PageSection from "../../Shared/PageSection";
import StackedColumnChart from "../../Shared/StackedColumnChart";
import LineChart from "../../Shared/LineChart";
import DotEditor from "../ResidentDaysEditor";

export default {
  name: "medication-usage-page",
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    "progress-overlay": ProgressOverlay,
    "stacked-column-chart": StackedColumnChart,
    "line-chart": LineChart,
    DotEditor,
  },
  watch: {
    "detailsSection.pagination.size"() {
      this.getDetails();
    },
    "detailsSection.pagination.page"() {
      this.getDetails();
    },
  },
  computed: {
    detailsTableReady() {
      return this.global.medications !== null && !this.detailsSection.loading;
    },
  },
  data() {
    return {
      global: {
        settings: {},
        filters: {},
        defaultDateRange: [moment().subtract(3, "months").startOf("month").toDate(), moment().subtract(1, "months").endOf("month").toDate()],
        sites: [],
        audits: [],
        momentGroups: [],
        criteria: {},
        facilities: [],
        showMoreFilterModal: false,
        additionalFilters: [/*'medications', */ "origins", "employeeTypes"],
        medications: null,
        reverseMedications: {},
        providers: {},
        showDaysOfTherapy: false,
      },
      summarySection: {
        loading: true,
        data: [],
        showTrueCompliance: false,
        exportModels: {},
      },
      detailsSection: {
        loading: true,
        loadingDependencies: true,
        editFormValues: {},
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        exportingObservations: false,
        observationExportProgress: 0,
        observationTitle: "",
        loadingErrorDialog: false,
        exportingErrorDialog: false,
        data: [],
      },
      graphSection: {
        loading: true,
        groupOptions: {
          Medication: "medication",
          "Infection Site": "infectionSite",
          "Infection Site Type": "infectionSiteSubType",
          "Primary Organism": "primaryOrganism",
          "Individual Type": "individualType",
          Facility: "facility",
          Unit: "unit",
        },
        groupBy: "Infection Site",
        timeScale: "Month",
        series: [],
        title: "Infection Rates",
        yAxis: "Infection Rate",
        xAxis: [],
        plotLines: [],
        customizations: {
          legend: {
            enabled: true,
          },
          plotOptions: {
            series: {
              dataLabels: {
                format: "{y}",
              },
            },
            column: {
              stacking: undefined,
            },
          },
        },
        chartType: "stackedColumn",
      },
    };
  },
  methods: {
    getFilterKeys() {
      return ["infectionOnsetDate", "useCurrentLocation", "basicInfectionSites", "basicInfectionSiteSubTypes", "resolutions", "primaryOrganisms", "caseStatus", "clientType", "clientStatus", "useAlternateResidentDaysRate"];
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "units":
                k = "unitIds";
                break;
              case "resolutions":
                k = "outcomes";
                v = v[0] === 0 ? ["all"] : [v[0]];
                break;
              // case ('medications'):
              //   v = v.flatMap(id => {
              //     const label = this.global.medications[id].displayText
              //     return this.global.reverseMedications[label]
              //   })
              //   break
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      this.global.filters.onsetDateFrom = moment(filterValues.infectionOnsetDate[0]).startOf("day").format();
      this.global.filters.onsetDateTo = moment(filterValues.infectionOnsetDate[1]).endOf("day").format();
      delete this.global.filters.infectionOnsetDate;
      if (rawFilters.resolutions && rawFilters.resolutions[1]) this.global.filters.outcomeSubTypes = [rawFilters.resolutions[1]];
      if (filterValues.basicInfectionSites) {
        this.global.filters.infectionSites = filterValues.basicInfectionSites;
      }
      delete this.global.filters.basicInfectionSites;
      if (filterValues.basicInfectionSiteSubTypes) {
        this.global.filters.infectionSiteSubTypes = filterValues.basicInfectionSiteSubTypes;
      }
      delete this.global.filters.basicInfectionSiteSubTypes;
      this.refreshAllData();
    },
    tagType: function (symptom) {
      const palette = ["success", "primary", "warning", "danger"];
      return palette[symptom.colorCode] || "gray";
    },
    dateToTimeScale(dateObj) {
      switch (this.graphSection.timeScale) {
        case "Week":
          return `W${moment(dateObj).format("WW-YYYY")}`;
        case "Month":
          return `${moment(dateObj).format("YYYY-MM")}`;
        case "Quarter":
          return `Q${moment(dateObj).format("Q-YYYY")}`;
        default:
          return `${moment(dateObj).format("YYYY")}`;
      }
    },
    generateGraphData(groupedTotals, timePeriods) {
      let orderedTotals = [...groupedTotals.entries()];
      this.graphSection.xAxis = timePeriods;

      const infectionSiteColorPalette = {
        Respiratory: "blue",
        GI: "green",
        BSI: "red",
        UTI: "yellow",
        Skin: "pink",
        ARO: "black",
        AUTI: "orange",
        EENT: "purple",
        Other: "grey",
        Screening: "brown",
      };
      this.graphSection.series = orderedTotals.flatMap(([name, periods]) => {
        const color = this.graphSection.groupBy === "Infection Site" ? infectionSiteColorPalette[name] : undefined;
        return {
          name,
          color,
          data: timePeriods.map((period) => periods[period].count),
          dataLabels: {
            enabled: true,
          },
        };
      });
    },
    roundTwoDecimalPlaces(value) {
      return Math.round(value * 100) / 100;
    },
    generateSummaryTableData(groupedTotals, timePeriods) {
      this.summarySection.data = [];
      this.summarySection.columns = [...timePeriods];
      const orderedTotals = [...groupedTotals.entries()];

      const summationRow = { label: "Total", summationColumn: 0 };
      orderedTotals.forEach(([groupLabel, value]) => {
        const row = { label: groupLabel, ...value };
        this.summarySection.columns.forEach((colKey) => {
          row.summationColumn = row.summationColumn || 0;
          row.summationColumn += row[colKey].count;
          row.summationColumn = this.roundTwoDecimalPlaces(row.summationColumn);

          // sum up a column's count value
          summationRow[colKey] = summationRow[colKey] || { count: 0 };
          summationRow[colKey].count += row[colKey].count;
          summationRow[colKey].count = this.roundTwoDecimalPlaces(summationRow[colKey].count);
          summationRow.summationColumn += row[colKey].count;
          summationRow.summationColumn = this.roundTwoDecimalPlaces(summationRow.summationColumn);
        });
        this.summarySection.data.push(row);
      });
      this.summarySection.data.push(summationRow);
    },
    generateTreatments(record) {
      const values = record.medications
        .map((med) => {
          const { frequency, dosage, medication } = med;
          return `${medication || ""} ${dosage || ""} ${frequency || ""}`.trim();
        })
        .join(", ");
      return values;
    },
    generateDetailsTableData(record) {
      let model = { ...record };
      model.onsetDateFormatted = moment(record.onsetDate).format(this.$configStore.dateFormat());
      model.facility = this.global.facilities.filter((s) => s.id === Number(record.facId))[0].displayText;
      model.status = record.status ? "Confirmed" : "Suspected";
      model.symptoms = record.symptoms.map((s) => {
        return { ...s, tagType: this.tagType(s) };
      });
      model.treatment = this.generateTreatments(record);

      const { infectionSite, infectionType } = model;
      model.infection = `${infectionSite}${infectionType ? ` / ${infectionType}` : ""}`;

      const { firstName, lastName } = model.client;
      model.name = [lastName, firstName].join(", ");
      return model;
    },
    createSummary(summary) {
      const timeScale = this.graphSection.timeScale;
      const formatTime = {
        Week: (record) => `W${record.dategroup}-${record.year}`,
        Month: (record) => `${record.year}-${`${record.dategroup}`.padStart(2, "0")}`,
        Quarter: (record) => `Q${record.dategroup}-${record.year}`,
        Year: (record) => `${record.year}`,
      };

      const allTimePeriods = new Map();
      const groupKey = this.graphSection.groupOptions[this.graphSection.groupBy];

      const convertAndGroup = (record) => {
        const row = {
          time: formatTime[timeScale](record),
          group: record[groupKey],
          count: record.total,
          data: record,
        };
        allTimePeriods.set(row.time, { count: 0 });
        return row;
      };
      const orderedCounts = summary.map(convertAndGroup);
      const getSummaryDefaults = () => JSON.parse(JSON.stringify(Object.fromEntries(allTimePeriods)));

      let groupedTotals = new Map();

      orderedCounts.forEach((row) => {
        if (!groupedTotals.has(row.group)) {
          groupedTotals.set(row.group, getSummaryDefaults());
        }
        let values = groupedTotals.get(row.group);
        values[row.time] = values[row.time] || { count: 0 };
        values[row.time].count += row.count;
        groupedTotals.set(row.group, values);
      });
      return [groupedTotals, [...new Set(orderedCounts.map((c) => c.time))]];
    },
    exportSummaryToExcel() {
      this.summarySection.loading = true;
      const columns = [this.graphSection.groupBy, ...this.summarySection.columns, "Total"];
      const data = this.summarySection.data.map((row) => {
        return [row.label, ...this.summarySection.columns.map((period) => row[period].count), row.summationColumn];
      });
      this.createExcelFile("report.xlsx", columns, data);
      this.summarySection.loading = false;
    },
    async exportObservationsToExcel() {
      this.detailsSection.exportingObservations = true;
      this.detailsSection.observationExportProgress = 0;
      let pageProgress = 0;
      let hasMorePages = true;
      const size = 1000;
      const columns = ["Name", "Staff ID", "Patient ID", "Infection Site/Type", "Status", "Symptoms", "Outcome", "Outcome Detail", "Facility", "Location", "Primary Organism", "Result", "Treatment", "Onset Date"];

      const convertDataToColumns = (json) => {
        const data = this.generateDetailsTableData(json);
        const { name, infection, status, symptoms, outcome, outcomeSubType, facility, location, primaryOrganism, treatment, result, onsetDateFormatted } = data;
        return [name, data.client.staffId || null, data.client.patientId || null, infection, status, symptoms.map((s) => s.displayText).join(", "), outcome, outcomeSubType, facility, location, primaryOrganism, result, treatment, onsetDateFormatted];
      };

      const getPageOfData = async (page) => {
        return await this.$http
          .post(`${window.CONFIG.infection_api}/search`, {
            ...this.global.filters,
            page,
            size,
          })
          .then((response) => response.body);
      };
      let data = [];
      while (hasMorePages) {
        this.detailsSection.exportingErrorDialog = false;
        if (!this.detailsSection.exportingObservations) {
          this.cancelObservationExport();
          break;
        }
        const json = await getPageOfData(pageProgress + 1).catch((err) => {
          if (err.status === 0) {
            this.cancelObservationExport();
            this.detailsSection.exportingErrorDialog = true;
          }
        });
        this.detailsSection.observationExportProgress = Math.round(((pageProgress + 1) / json.totalPages) * 100);
        if (json.totalPages > pageProgress + 1) {
          pageProgress++;
        } else {
          hasMorePages = false;
        }
        data.push(json.content);
      }
      if (!this.detailsSection.exportingObservations) {
        return;
      }
      data = data.flat().map(convertDataToColumns);
      this.createExcelFile("report.xlsx", columns, data);
      this.detailsSection.exportingObservations = false;
      this.detailsSection.exportingErrorDialog = false;
    },
    createExcelFile: function (fileName, columns, data) {
      const detailsRow = util.generateExportDetailsRow();
      data.unshift(columns);
      data.unshift(detailsRow);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    cancelObservationExport() {
      this.detailsSection.exportingObservations = false;
      this.detailsSection.observationExportProgress = 0;
    },
    async getDetails() {
      this.detailsSection.loading = true;
      const json = await this.$http
        .post(`${window.CONFIG.infection_api}/search`, {
          ...this.global.filters,
          page: this.detailsSection.pagination.page,
          size: this.detailsSection.pagination.size,
        })
        .then((response) => response.body)
        .catch((err) => {
          if (err.status === 0) {
            this.detailsSection.loadingErrorDialog = true;
          }
          this.detailsSection.loading = false;
          return {
            page: 0,
            content: [],
            totalElements: 0,
          };
        });
      this.detailsSection.pagination.total = json.totalElements;
      this.detailsSection.data = json.content.map(this.generateDetailsTableData);
      this.detailsSection.loading = false;
    },
    async getSummary() {
      this.graphSection.loading = true;
      this.summarySection.loading = true;

      const summary = await this.$http
        .post(`${window.CONFIG.infection_api}/rate/summary`, {
          ...this.global.filters,
          groupBy: this.graphSection.groupOptions[this.graphSection.groupBy],
          timeScale: this.graphSection.timeScale.toLowerCase(),
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        .then((response) => response.body)
        .catch(() => {
          this.summarySection.loading = false;
          this.graphSection.loading = false;
          return [];
        });
      const [groups, periods] = this.createSummary(summary);
      this.summarySection.exportModels = { groups, periods };
      this.generateSummaryTableData(groups, periods);
      this.generateGraphData(groups, periods);
      this.graphSection.loading = false;
      this.summarySection.loading = false;
    },
    async refreshAllData() {
      if (this.detailsTableReady) {
        this.getDetails();
      }
      this.getSummary();
    },
    editDaysOfTherapy() {
      this.global.showDaysOfTherapy = true;
    },
    hideDaysOfTherapy() {
      this.global.showDaysOfTherapy = false;
    },
  },
  async created() {
    console.clear();
    this.global.facilities = this.$configStore.data.sites;
    this.$http
      .get(`${window.CONFIG.api}/clients/search`, {
        params: {
          providerType: "Yes",
          status: "C",
          clientType: "Staff",
        },
      })
      .then((resp) => resp.json())
      .then((providers) => {
        this.global.providers = util.arrayToObj(providers, "id");
      });
    await this.$http
      .get(`${window.CONFIG.treatement_api}/config`)
      .then((resp) => resp.json())
      .then((json) => [...json.medications, ...json.archivedMedications])
      .then((meds) => {
        this.global.medications = util.arrayToObj(meds, "id");
        this.global.reverseMedications = [...meds].reduce((hash, curr) => {
          hash[curr.displayText] = hash[curr.displayText] || [];
          hash[curr.displayText] = [...hash[curr.displayText], curr.id];
          return hash;
        }, {});
        this.getDetails();
      });
  },
};
</script>

<style>
.infection-rates {
  --status-missed: #ff5050;
  --status-complete: #00d560;
  --status-incomplete: #ffcc00;
}
.infection-rates .outcome {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: var(--status-missed);
}
.infection-rates .complete {
  background-color: var(--status-complete);
}
.infection-rates .incomplete {
  background-color: var(--status-incomplete);
}
.infection-rates .complianceToggle {
  margin-right: 10px;
}
.infection-rates .comments {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.infection-rates .bracket {
  color: #a7a7a7;
}
.infection-rates .dotButton.el-button--warning {
  color: black;
}
</style>
