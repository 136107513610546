<template>
  <div class="residents">
    <!-- Clients Filters -->
    <filters-section :showLocation="true" :defaultValues="global.filterDefaults" :filterDisplayKeys="getFilterKeys()" v-on:change="formatFilters"> </filters-section>
    <!-- Clients Table -->
    <page-section :title="`${this.isStaffPage ? 'Staff' : 'Residents'} - Total: ${clientsSection.pagination.total}`" v-loading="clientsSection.loading">
      <template slot="title">
        <div class="case-filter">
          <el-button class="case-filter-button" :disabled="!canModify || (!isStaffPage && pccIntegrationEnabled)" v-if="showNewClientButton" :plain="true" type="primary" size="mini" @click="createClient()">+ New {{ isStaffPage ? "Staff" : "Resident" }}</el-button>
          <el-button class="case-filter-button" :disabled="!canModify || (!isStaffPage && pccIntegrationEnabled)" v-if="showBatchImmunizationsButton" :plain="true" type="primary" size="mini" @click="createBatchImmunizations()">Batch Immunizations</el-button>
          <el-tooltip v-if="global.filters.facIds && global.filters.facIds.length !== 1" effect="dark" content="Please select a single facility" placement="bottom">
            <el-button :disabled="true" class="case-filter-button" size="mini" type="primary" :plain="true">Export Immunizations Data</el-button>
          </el-tooltip>
          <el-button v-else class="case-filter-button" :disabled="!canModify || !global.filters.facIds" :plain="true" type="primary" size="mini" @click="exportImmunizations()"> Export Immunizations Data</el-button>
          <el-select class="case-filter-select" size="mini" v-model="clientsSection.sortBy">
            <el-option v-for="item in global.sortByOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-pagination
            @size-change="
              (size) => {
                clientsSection.pagination.size = size;
              }
            "
            @current-change="
              (page) => {
                clientsSection.pagination.page = page;
              }
            "
            :page-sizes="[5, 10, 20, 50]"
            :page-size="clientsSection.pagination.size"
            layout="sizes, prev, pager, next"
            :total="clientsSection.pagination.total"
          >
          </el-pagination>
        </div>
      </template>
      <el-table :data="clientsSection.data" v-on:row-click="editClient">
        <el-table-column fixed label=" " width="100">
          <template scope="scope">
            <div style="padding: 0.5em">
              <div :span="4" class="profile-row-photo"><img :src="getClientPhoto(scope.row)" /></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Name" fixed width="180">
          <template scope="scope">
            <span style="word-break: normal">{{ scope.row.nameFormatted }}</span
            ><br />
            ({{ isStaffPage ? scope.row.staffId : patientIdType ? scope.row[patientIdType] || scope.row.patientId : scope.row.patientId }})
          </template>
        </el-table-column>
        <el-table-column prop="facilitiesFormatted" label="Facility" width="140"> </el-table-column>
        <el-table-column v-if="isStaffPage" prop="unitFormatted" label="Unit" width="140"> </el-table-column>
        <el-table-column v-if="!isStaffPage" prop="locationFormatted" label="Location" width="140"> </el-table-column>
        <el-table-column prop="statusFormatted" label="Status" width="140"> </el-table-column>
        <el-table-column v-if="isStaffPage" prop="providerType" label="Provider" width="140"> </el-table-column>
        <el-table-column v-if="isStaffPage" prop="employeeType" label="Position" width="140"> </el-table-column>
        <el-table-column prop="genderFormatted" label="Gender" width="140"> </el-table-column>
        <el-table-column prop="dateOfBirthFormatted" label="DOB" min-width="140"> </el-table-column>
        <div v-if="isStaffPage">
          <el-table-column fixed="right" label="" prop="active" width="200">
            <template scope="scope">
              <el-button :disabled="!canModify || scope.row.status === 'C' || isExt" :plain="true" size="mini" @click="activateStaff(scope.row)">Activate</el-button>
              <el-button :disabled="!canModify || scope.row.status === 'D' || isExt" :plain="true" size="mini" @click="inactivateStaff(scope.row)">Inactivate</el-button>
            </template>
          </el-table-column>
        </div>
        <div v-else>
          <el-table-column v-if="!adtEnabled" fixed="right" label="" prop="active" width="300">
            <template scope="scope">
              <el-button :disabled="!canModify || pccIntegrationEnabled || scope.row.bed !== null" :plain="true" size="mini" @click="assignResident(scope.row)">Assign</el-button>
              <el-button :disabled="!canModify || pccIntegrationEnabled || scope.row.bed === null" :plain="true" size="mini" @click="unassignResident(scope.row.id)">Unassign</el-button>
              <el-button :disabled="!canModify || pccIntegrationEnabled || scope.row.status === 'D'" :plain="true" size="mini" @click="dischargeResident(scope.row)">Discharge</el-button>
            </template>
          </el-table-column>
        </div>
      </el-table>
    </page-section>

    <!-- Client Creator -->
    <el-dialog v-if="clientsSection.creator.visible" v-model="clientsSection.creator.visible" :title="clientsSection.creator.title" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <client-editor
        :client="clientsSection.creator.data"
        :isStaffPage="isStaffPage"
        v-on:close="closeClientCreator"
        v-on:editor-close="closeClientCreator"
        v-on:editor-error="
          closeClientCreator();
          setError('There was an error creating the client. Please click refresh to try again.');
        "
        v-on:editor-save="
          closeClientCreator();
          getClients();
        "
        v-on:editor-assign="
          closeClientCreator();
          assignResident($event);
        "
      ></client-editor>
    </el-dialog>

    <!-- Client Editor (client tab + location + immunizations) -->
    <el-dialog :key="clientsSection.editor.data.id" v-if="clientsSection.editor.visible" v-model="clientsSection.editor.visible" :title="clientsSection.editor.title" size="large">
      <el-tabs v-model="clientsSection.editor.activeTab" type="card">
        <el-tab-pane :label="`${isStaffPage ? 'Staff' : 'Resident'} Info`" name="1">
          <client-tab
            v-if="clientsSection.editor.activeTab === '1'"
            :client="clientsSection.editor.data"
            :isStaffPage="isStaffPage"
            v-on:upload-success="getClients"
            v-on:editor-save="
              closeClientEditor();
              getClients();
            "
            v-on:editor-close="
              closeClientEditor();
              getClients();
            "
            v-on:editor-error="
              closeClientEditor();
              setError('There was an error updating the client. Please click refresh to try again.');
            "
          ></client-tab>
        </el-tab-pane>
        <el-tab-pane label="Location" name="2" v-if="!isStaffPage">
          <template v-if="clientsSection.editor.activeTab === '2'">
            <location-tab :locations="clientsSection.editor.locations" :beds="clientsSection.editor.bedMap"></location-tab>
          </template>
        </el-tab-pane>

        <el-tab-pane label="Immunizations" :name="isStaffPage ? '2' : '3'">
          <template v-if="clientsSection.editor.activeTab === (isStaffPage ? '2' : '3')">
            <immunizations-tab :clientName="getClientName(clientsSection.editor.data)" :clientId="clientsSection.editor.data.id || 1" :showActions="true" :isStaff="isStaffPage"></immunizations-tab>
          </template>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- Assign Resident to Bed -->
    <bed-assignment
      v-if="!isStaffPage && clientsSection.assign.visible"
      :resident="clientsSection.assign.data"
      :title="clientsSection.assign.title"
      v-on:bed-assignment-close="cancelAssignResident"
      v-on:bed-assignment-error="
        cancelAssignResident();
        setError('There was an error updating the client. Please click refresh to try again.');
      "
      v-on:bed-assignment-save="
        cancelAssignResident();
        getClients();
      "
    ></bed-assignment>

    <!-- Create Batch Immunizations -->
    <immunizations-editor v-if="immunizationsSection.editor.visible" :title="'Batch Immunizations'" :immunization="{}" :isBatch="true" :isStaff="isStaffPage" v-on:immunization-editor-save="batchImmunizationsClose" v-on:immunization-editor-close="batchImmunizationsClose"> </immunizations-editor>

    <!-- Immunizations Export Progress -->
    <progress-overlay v-if="immunizationsSection.export.visible" :progress="immunizationsSection.export.progress" v-on:progress-cancelled="cancelImmunizationsExport" title="Download In Progress"> </progress-overlay>

    <!-- Error Dialog -->
    <el-dialog title="Error, please try again" v-model="errorSection.visible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      {{ errorSection.message }}
      <div class="dialog-footer">
        <el-button
          @click="
            errorSection.visible = false;
            errorSection.message = 'An error occurred. Please click refresh to try again.';
            getClients();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import auth from "../../auth";
import XLSX from "xlsx";
import api from "../../services/restClient";
import util from "../../util";
import moment from "moment";
import FiltersSection from "../Shared/FiltersSection";
import PageSection from "../Shared/PageSection";
import ClientEditor from "./Editor";
import ClientTab from "./Tab.vue";
import BedAssignment from "./Residents/BedAssignment.vue";
import ImmunizationsEditor from "../Immunizations/Editor.vue";
import ImmunizationsTab from "../Immunizations/Tab";
import LocationsTab from "../SurveillanceLocation";
import ProgressOverlay from "../ProgressBarOverlay";

export default {
  name: "clients",
  props: ["isStaffPage"],
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    "bed-assignment": BedAssignment,
    "client-editor": ClientEditor,
    "client-tab": ClientTab,
    "immunizations-tab": ImmunizationsTab,
    "immunizations-editor": ImmunizationsEditor,
    "location-tab": LocationsTab,
    "progress-overlay": ProgressOverlay,
  },
  watch: {
    "clientsSection.pagination.size"() {
      this.getClients();
    },
    "clientsSection.pagination.page"() {
      this.getClients();
    },
    "clientsSection.sortBy"() {
      this.getClients();
    },
  },
  computed: {
    showNewClientButton() {
      /* client creation button is shown if:
       *  - on staff page AND user's org is not ext
       *  OR
       *  - on resident page AND adt is not enabled AND pcc is not enabled
       */
      return (this.isStaffPage && !this.isExt) || (!this.isStaffPage && !this.adtEnabled && !this.pccIntegrationEnabled);
    },
    showBatchImmunizationsButton() {
      /* batch imm button is shown if:
       *  - on staff page
       *  OR
       *  - on resident page AND adt is not enabled AND pcc is not enabled
       */
      return this.isStaffPage || (!this.isStaffPage && !this.adtEnabled && !this.pccIntegrationEnabled);
    },
  },
  data() {
    return {
      canModify: false,
      adtEnabled: false,
      pccIntegrationEnabled: false,
      isExt: false,
      patientIdType: undefined,
      global: {
        facilities: [],
        filters: {},
        filterDefaults: {},
        sortByOptions: [
          {
            label: "Sort by First Name",
            value: "firstName",
          },
          {
            label: "Sort by Last Name",
            value: "lastName",
          },
          {
            label: "Sort by ID",
            value: "id",
          },
          {
            label: "Sort by Location",
            value: "location",
          },
        ],
      },
      errorSection: {
        visible: false,
        message: "An error occurred. Please click refresh to try again.",
      },
      immunizationsSection: {
        editor: {
          visible: false,
        },
        export: {
          visible: false,
          progress: 0,
          exporting: false,
        },
      },
      clientsSection: {
        loading: true,
        data: [],
        sortBy: "firstName",
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        creator: {
          visible: false,
          data: null,
        },
        editor: {
          locations: [],
          visible: false,
          activeTab: "1",
          bedMap: {},
          data: null,
        },
        assign: {
          visible: false,
          data: null,
          title: "",
        },
      },
    };
  },
  methods: {
    getFilterKeys() {
      if (this.isStaffPage) {
        return ["assigned", "staffStatus", "positions", "provider", "firstName", "lastName"];
      } else {
        return ["assigned", "clientStatus", "firstName", "lastName"];
      }
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "units":
                k = "unitIds";
                break;
              case "clientStatus":
                k = "status";
                break;
              case "staffStatus":
                k = "status";
                break;
              case "positions":
                k = "employeeTypes";
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      if (this.global.filters.assigned === "all") {
        delete this.global.filters.assigned;
      }
      this.getClients();
    },
    generateClientsTableData(record) {
      let model = { ...record };
      model.nameFormatted = this.getClientName(record);
      model.facilitiesFormatted = record.facilities.length ? record.facilities.map(this.getFacilityName) : this.getFacilityName(record.facId);
      model.unitFormatted = this.getUnitName(record.unitId, record);
      model.statusFormatted = this.getStatusText(record);
      model.locationFormatted = record?.bed?.fullDesc || "Not Assigned";
      model.genderFormatted = record.gender || "Not Entered";
      model.dateOfBirthFormatted = record.dateOfBirth ? moment.utc(record.dateOfBirth).format("MM-DD-YYYY") : "Not Entered";
      return model;
    },
    async getClients() {
      this.clientsSection.loading = true;
      const filters = {
        ...this.global.filters,
        sortBy: this.clientsSection.sortBy,
        page: this.clientsSection.pagination.page,
        size: this.clientsSection.pagination.size,
        clientType: this.getClientTypeFilter,
      };
      try {
        const response = await this.$http.get(`${window.CONFIG.client_api}`, { params: filters });
        this.clientsSection.pagination.total = response.body.totalElements;
        this.clientsSection.data = response.body.content.map(this.generateClientsTableData);
      } catch (err) {
        console.log({ err });
        this.clientsSection.pagination.total = 0;
        this.clientsSection.data = [];
        this.setError("There was an error loading clients. Please click refresh to try again.");
      }
      this.clientsSection.loading = false;
    },
    assignResident(client) {
      this.clientsSection.assign.data = { ...client };
      this.clientsSection.assign.title = this.getClientModalTitle(client);
      this.clientsSection.assign.visible = true;
    },
    cancelAssignResident() {
      this.clientsSection.assign.visible = false;
      this.clientsSection.assign.data = {};
    },
    editClient(row, event, column) {
      if (column.property === "active") {
        return;
      }
      this.clientsSection.editor.activeTab = "1";
      this.clientsSection.editor.title = this.getClientModalTitle(row);
      this.clientsSection.editor.data = { ...row };
      if (!this.isStaff) {
        api.getLocationsFromOcc(row.id, (response) => {
          this.clientsSection.editor.locations = response.body;
          this.clientsSection.editor.visible = true;
        });
      }
    },
    closeClientEditor() {
      this.clientsSection.editor.visible = false;
      this.clientsSection.editor.data = {};
    },
    createClient() {
      this.clientsSection.creator.title = this.isStaffPage ? "Staff" : "Resident";
      this.clientsSection.creator.data = {};
      this.clientsSection.creator.visible = true;
    },
    closeClientCreator() {
      this.clientsSection.creator.visible = false;
      this.clientsSection.creator.data = {};
    },
    createBatchImmunizations() {
      this.immunizationsSection.editor.visible = true;
    },
    batchImmunizationsClose() {
      this.immunizationsSection.editor.visible = false;
    },
    async activateStaff(staff) {
      this.clientsSection.loading = true;
      staff.status = "C";
      await this.updateStaffStatus(staff);
    },
    async inactivateStaff(staff) {
      this.clientsSection.loading = true;
      staff.status = "D";
      await this.updateStaffStatus(staff);
    },
    async updateStaffStatus(staff) {
      try {
        await this.$http.put(window.CONFIG.api + "/clients/" + staff.id, staff);
        await this.getClients();
      } catch (err) {
        this.setError("There was an error updating the staff. Please click refresh to try again.");
      }
    },
    async unassignResident(id) {
      this.clientsSection.loading = true;
      try {
        await this.$http.delete(`${window.CONFIG.api}/client/${id}/bed/unassign`);
        this.$configStore.loadAll(async () => {
          this.bedMap = this.$configStore.data.bedMap;
          await this.getClients();
        });
      } catch (err) {
        this.setError("There was an error unassigning the resident. Please click refresh to try again.");
      }
    },
    async dischargeResident(client) {
      await this.$confirm("Do you want to discharge " + this.getClientModalTitle(client) + "?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        type: "warning",
      });
      try {
        await this.$http.delete(`${window.CONFIG.api}/clients/${client.id}/discharge`);
        await this.getClients();
      } catch (err) {
        this.setError("There was an error discharging the resident. Please click refresh to try again.");
      }
    },
    async exportImmunizations() {
      this.immunizationsSection.export.exporting = true;
      this.immunizationsSection.export.progress = 0;
      const columns = ["ID", "First Name", "Middle Name", "Last Name", this.isStaffPage ? "Staff ID" : "Patient ID", "Facility", "Immunization Type", "Vaccine Type", "Location", "Lot Number", "Declined?", "Reason", "Administration Date", "Expiry Date", "Notes"];
      const convertDataToColumns = (json) => {
        const data = this.formatImmunizationExportData(json);
        const { id, note, client, facility, description, vaccineType, location, lotNumber, declinedFormatted, declinedReason, dateFormatted, expiryFormatted } = data;
        const clientId = this.isStaffPage ? client.staffId : this.patientIdType ? client[this.patientIdType] : client.patientId;
        return [id, client.firstName, client.middleName || "", client.lastName, clientId, facility, description, vaccineType, location, lotNumber, declinedFormatted, declinedReason, dateFormatted, expiryFormatted, note];
      };
      let data = await this.getImmunizationExport();

      if (!this.immunizationsSection.export.exporting) {
        return;
      }
      data = data.flat().map(convertDataToColumns);
      this.createExcelFile("report.xlsx", columns, data);
      this.immunizationsSection.export.exporting = false;
    },
    createExcelFile: function (fileName, columns, data) {
      const detailsRow = util.generateExportDetailsRow();
      data.unshift(columns);
      data.unshift(detailsRow);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    getImmunizationExport() {
      // remap status -> clientStatus for imm API
      const { status, ...otherFilters } = this.global.filters;
      const filters = { ...otherFilters, clientStatus: status };
      return this.$http
        .get(`${window.CONFIG.immunization_api}/export`, {
          params: {
            ...filters,
            clientType: this.getClientTypeFilter,
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((response) => response.body);
    },
    getClientTypeFilter() {
      return this.isStaffPage ? "Staff" : "Client";
    },
    formatImmunizationExportData(record) {
      let model = { ...record };
      model.dateFormatted = moment(record.date).format(this.$configStore.dateFormat());
      model.expiryFormatted = record.expiry ? moment(record.expiry).format(this.$configStore.dateFormat()) : "";
      model.facility = this.global.facilities.filter((s) => s.id === Number(record.client.facId))[0].displayText;
      model.declinedFormatted = record.declined ? "Yes" : "No";
      const { firstName, lastName, middleName } = model.client;
      model.name = [firstName, middleName, lastName].filter(Boolean).join(" ");
      return model;
    },
    getFacilityName(facId) {
      if (facId) {
        const facility = this.global.facilities.find((s) => s.id === facId);
        return facility ? facility.displayText : "";
      }
      return "";
    },
    getUnitName(unitId, record) {
      const facility = this.global.facilities.find((facility) => facility.units.some((unit) => unit.id === unitId));
      if (!facility) {
        return "Not assigned";
      }
      const unit = facility.units.find((unit) => unit.id === unitId);
      if (!unit) {
        return "Not assigned";
      }
      const unitName = unit.displayText;
      const facilityName = unit.site.displayText;
      return record.facilities.length > 1 ? `${unitName} (${facilityName})` : unitName;
    },
    setError(message) {
      this.errorSection.message = message;
      this.errorSection.visible = true;
    },
    getClientPhoto(client) {
      if (client && client.photo) {
        const f = client.photo;
        if (f.indexOf("/") !== -1) {
          return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
        }

        return "../../static/" + (client.photo ? client.photo : "user") + ".png";
      }

      return "../../static/user.png";
    },
    getClientName(client) {
      const firstName = client.firstName ? client.firstName + " " : "";
      const middleName = client.middleName ? client.middleName + " " : "";
      const lastName = client.lastName ? client.lastName : "";

      if (this.clientsSection.sortBy === "lastName") {
        return lastName + ", " + firstName + middleName;
      } else {
        return firstName + middleName + lastName;
      }
    },
    getClientModalTitle(client) {
      const { firstName, lastName } = client;
      return firstName ? `${firstName} ${lastName}` : "Client";
    },
    getStatusText(client) {
      let statusText;
      switch (client.status) {
        case "T":
          statusText = "Transferred";
          break;
        case "D":
          statusText = this.isStaffPage ? "Inactive" : "Discharged";
          break;
        default:
          statusText = "Active";
          break;
      }
      return statusText;
    },
    isAdminStaff() {
      return auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    },
    assignFilterDefaults() {
      if (this.isStaffPage) {
        this.global.filterDefaults.staffStatus = "C";
      } else {
        this.global.filterDefaults.clientStatus = "C";
        this.global.filterDefaults.assigned = true;
      }
    },
  },
  async created() {
    console.clear();
    this.assignFilterDefaults();
    this.canModify = auth.canModify();
    this.adtEnabled = this.$configStore.adtEnabled();
    this.pccIntegrationEnabled = this.$configStore.pccIntegrationEnabled();
    this.isExt = auth.userInfo().org === "ext" || auth.userInfo().org === "extdemo";
    this.patientIdType = this.$configStore.patientIdType();
    this.global.facilities = this.$configStore.data.sites;
  },
};
</script>

<style>
.el-dialog__body div.profile-row-photo {
  width: 10em;
  height: 10em;
  overflow: hidden;
  border-radius: 5em;
}

.el-dialog__body img.profile-row-photo {
  height: 100%;
  width: auto;
  transform: translateX(-50%);
  margin-left: 50%;
}
div.profile-photo {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
  display: block;
  overflow: hidden;
  border-radius: 50%;
}

.profile-photo img {
  width: auto;
  height: 100%;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-filter-select {
  width: 160px;
  margin-right: 1em;
}
.case-filter > .case-filter-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}
</style>