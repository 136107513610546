<template>
  <div class="immunization-rates">
    <filters-section :filterDisplayKeys="global.filterKeys" :dateRangeStart="global.defaultDateStart" :defaultValues="global.defaultFilters" v-on:change="formatFilters">
      <div slot="immunizationDate-append">
        <el-button :plain="true" class="filter-append" size="mini" @click="useExpiryDateFilter">Use Expiry Date</el-button>
      </div>
      <div slot="expiryDate-append">
        <el-button :plain="true" class="filter-append" size="mini" @click="useAdministrationDateFilter">Use Administration Date</el-button>
      </div>
    </filters-section>
    <page-section title="Graph" v-loading="graphSection.loading">
      <template slot="title">
        <span class="label">Group by</span>
        <el-radio-group v-model="graphSection.groupBy" size="small" @change="refreshAllData">
          <el-radio-button label="Immunization Type"></el-radio-button>
          <el-radio-button label="Vaccine Type"></el-radio-button>
          <el-radio-button label="Individual Type"></el-radio-button>
          <el-radio-button label="Facility"></el-radio-button>
        </el-radio-group>
        <!-- unneeded as currently there is only one timescale -->
        <!-- <span class="label">and</span>
        <el-radio-group v-model="graphSection.timeScale" size="small">
          <el-radio-button label="Year"></el-radio-button>
        </el-radio-group> -->
      </template>
      <div style="margin: 10px">
        <stacked-column-chart :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxis" :yAxisPlotLines="graphSection.plotLines" :usePerColumnLabels="true" :configuration="graphSection.customizations"></stacked-column-chart>
      </div>
    </page-section>

    <page-section title="Summary" v-loading="summarySection.loading">
      <template slot="title">
        <el-button size="small" @click="exportSummaryToExcel">Export Data</el-button>
      </template>
      <el-table :data="summarySection.data">
        <el-table-column prop="label" :label="graphSection.groupBy"></el-table-column>
        <el-table-column label="Total">
          <template slot-scope="scope">
            {{ scope.row.total }}
          </template>
        </el-table-column>
      </el-table>
    </page-section>
    <page-section :title="`Analysis Details (${immunizationsSection.pagination.total})`" v-if="!immunizationsSection.loading">
      <template slot="title">
        <el-button size="small" v-if="global.canSync" @click="openSyncImmunizations" type="warning">Sync Immunizations</el-button>
        <el-button size="small" :disabled="immunizationsSection.pagination.total > global.exportLimit" @click="exportImmunizationsToExcel()">Export Data</el-button>
        <el-pagination
          @size-change="
            (size) => {
              immunizationsSection.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              immunizationsSection.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="immunizationsSection.pagination.size"
          layout="sizes, prev, pager, next"
          :total="immunizationsSection.pagination.total"
        >
        </el-pagination>
      </template>
      <el-table :data="immunizationsSection.data">
        <el-table-column label="Name" width="150" fixed>
          <template scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
            ({{ scope.row.client.staffId || scope.row.client.patientId }})
          </template>
        </el-table-column>
        <el-table-column v-if="global.facilities.length > 1" prop="facility" label="Facility" min-width="200"> </el-table-column>
        <el-table-column prop="description" label="Immunization Type" min-width="150"> </el-table-column>
        <el-table-column prop="vaccineType" label="Vaccine Type" min-width="150"> </el-table-column>
        <el-table-column prop="location" label="Location" min-width="100"> </el-table-column>
        <el-table-column prop="lotNumber" label="Lot Number" min-width="100"> </el-table-column>
        <el-table-column prop="declinedFormatted" label="Declined?" min-width="75"> </el-table-column>
        <el-table-column prop="declinedReason" label="Reason" min-width="100"> </el-table-column>
        <el-table-column prop="dateFormatted" label="Administration Date" min-width="100"> </el-table-column>
        <el-table-column prop="expiryFormatted" label="Expiry Date" min-width="100"> </el-table-column>
      </el-table>
    </page-section>
    <el-dialog v-if="immunizationsSection.immunizationSyncVisible" v-model="immunizationsSection.immunizationSyncVisible" title="Sync Immunizations" :show-close="false" size="tiny">
      <sync-immunizations
        v-on:immunization-sync-close="closeImmunizationSync"
        v-on:immunization-sync-complete="
          closeImmunizationSync();
          refreshAllData();
        "
      ></sync-immunizations>
    </el-dialog>
  </div>
</template>

<script>
import ProgressOverlay from "../../../components/ProgressBarOverlay";
import FiltersSection from "../../Shared/FiltersSection";
import PageSection from "../../Shared/PageSection";
import StackedColumnChart from "../../Shared/StackedColumnChart";
import moment from "moment";
import XLSX from "xlsx";
import util from "../../../util";
import SyncImmunizationsForFacility from "../../Immunizations/SyncImmunizationsForFacility";
import auth from "@/auth";

export default {
  name: "medication-usage-page",
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    "progress-overlay": ProgressOverlay,
    "stacked-column-chart": StackedColumnChart,
    "sync-immunizations": SyncImmunizationsForFacility,
  },
  watch: {
    "immunizationsSection.pagination.size"() {
      this.getImmunizations();
    },
    "immunizationsSection.pagination.page"() {
      this.getImmunizations();
    },
  },
  data() {
    return {
      global: {
        canSync: false,
        exportLimit: 10000,
        facilities: [],
        filters: {},
        filterKeys: ["immunizationDate", "immunizationTypes", "vaccineTypes", "declined", "oneRecordOnly", "clientType", "clientStatus"],
        defaultFilters: {
          clientStatus: "C",
        },
        defaultDateStart: moment().startOf("year").toDate(),
      },
      immunizationsSection: {
        loading: true,
        exporting: false,
        immunizationSyncVisible: false,
        exportProgress: 0,
        data: [],
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
      },
      graphSection: {
        loading: true,
        groupBy: "Immunization Type",
        timeScale: "Year",
        groupOptions: {
          "Immunization Type": "immunizationType",
          "Vaccine Type": "vaccineType",
          "Individual Type": "clientType",
          Facility: "facility",
        },
        series: [],
        title: "Immunization Rates",
        yAxis: "Immunization Rate (%)",
        xAxis: [],
        plotLines: [],
        customizations: {
          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              dataLabels: {
                format: "{y}%",
              },
            },
            column: {
              stacking: undefined,
            },
          },
        },
      },
      summarySection: {
        loading: true,
        data: [],
        columns: [],
        exportModels: {},
      },
    };
  },
  methods: {
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "units":
                k = "unitIds";
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      if (filterValues.immunizationDate) {
        this.global.filters.dateRangeStart = moment(filterValues.immunizationDate[0]).startOf("day").format();
        this.global.filters.dateRangeEnd = moment(filterValues.immunizationDate[1]).endOf("day").format();
        delete this.global.filters.immunizationDate;
      }
      if (filterValues.expiryDate) {
        this.global.filters.expiryDateRangeStart = moment(filterValues.expiryDate[0]).startOf("day").format();
        this.global.filters.expiryDateRangeEnd = moment(filterValues.expiryDate[1]).endOf("day").format();
        delete this.global.filters.expiryDate;
      }
      if (this.global.filters.declined === "all") {
        delete this.global.filters.declined;
      }
      this.refreshAllData();
    },
    async getImmunizations() {
      const json = await this.$http
        .get(`${window.CONFIG.immunization_api}`, {
          params: {
            ...this.global.filters,
            groupBy: this.graphSection.groupOptions[this.graphSection.groupBy],
            timeScale: this.graphSection.timeScale.toLowerCase(),
            page: this.immunizationsSection.pagination.page,
            size: this.immunizationsSection.pagination.size,
          },
        })
        .then((response) => response.body)
        .catch((err) => {
          if (err.status === 0) {
            this.immunizationsSection.loadingErrorDialog = true;
          }
          this.immunizationsSection.loading = false;
          return {
            page: 0,
            content: [],
            totalElements: 0,
          };
        });
      this.immunizationsSection.pagination.total = json.totalElements;
      this.immunizationsSection.data = json.content.map(this.generateImmunizationsTableData);
      this.immunizationsSection.loading = false;
    },
    generateImmunizationsTableData(record) {
      let model = { ...record };
      model.dateFormatted = moment(record.date).format(this.$configStore.dateFormat());
      model.expiryFormatted = record.expiry ? moment(record.expiry).format(this.$configStore.dateFormat()) : "";
      model.facility = this.global.facilities.filter((s) => s.id === Number(record.client.facId))[0].displayText;
      model.declinedFormatted = record.declined ? "Yes" : "No";
      const { firstName, lastName, middleName } = model.client;
      model.name = [firstName, middleName, lastName].filter(Boolean).join(" ");
      return model;
    },
    getImmunizationExport() {
      return this.$http
        .get(`${window.CONFIG.immunization_api}/export`, {
          params: {
            ...this.global.filters,
            groupBy: this.graphSection.groupOptions[this.graphSection.groupBy],
            timeScale: this.graphSection.timeScale.toLowerCase(),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((response) => response.body);
    },
    async getSummary() {
      this.graphSection.loading = true;
      this.summarySection.loading = true;
      const summary = await this.$http
        .get(`${window.CONFIG.immunization_api}/summary`, {
          params: {
            ...this.global.filters,
            groupBy: this.graphSection.groupOptions[this.graphSection.groupBy],
            timeScale: this.graphSection.timeScale.toLowerCase(),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((res) => res.body)
        .catch(() => {
          this.summarySection.loading = false;
          this.graphSection.loading = false;
          return [];
        });
      const [groups, periods] = this.createSummary(summary);
      this.summarySection.exportModels = { groups, periods };
      this.generateSummaryTableData(groups, periods);
      this.generateGraphData(groups, periods);
      this.graphSection.loading = false;
      this.summarySection.loading = false;
    },
    generateGraphData(groupedTotals, groups) {
      this.graphSection.xAxis = groups;
      this.graphSection.series = [
        {
          name: this.graphSection.groupBy,
          data: groups.map((group) => groupedTotals.get(group).count),
          dataLabels: {
            enabled: true,
          },
        },
      ];
    },
    roundTwoDecimalPlaces(value) {
      return Math.round(value * 100) / 100;
    },
    generateSummaryTableData(groupedTotals) {
      this.summarySection.data = [];
      this.summarySection.columns = ["Total"];

      groupedTotals.forEach((totals, groupLabel) => {
        const row = { label: groupLabel, total: totals.count };
        this.summarySection.data.push(row);
      });
    },
    createSummary(summary) {
      const groupKey = this.graphSection.groupOptions[this.graphSection.groupBy];

      const groupedTotals = new Map();
      summary.forEach((row) => {
        const group = row[groupKey];
        if (!groupedTotals.has(group)) {
          groupedTotals.set(group, { count: 0 });
        }
        groupedTotals.get(group).count += row.total;
      });

      return [groupedTotals, [...groupedTotals.keys()]];
    },
    async refreshAllData() {
      this.getImmunizations();
      this.getSummary();
    },
    async exportImmunizationsToExcel() {
      this.immunizationsSection.exporting = true;
      this.immunizationsSection.exportProgress = 0;
      const columns = ["Name", "Facility", "Immunization Type", "Vaccine Type", "Location", "Lot Number", "Declined?", "Reason", "Administration Date", "Expiry Date"];
      const convertDataToColumns = (json) => {
        const data = this.generateImmunizationsTableData(json);
        const { client, facility, description, vaccineType, location, lotNumber, declinedFormatted, declinedReason, dateFormatted, expiryFormatted } = data;
        const clientName = `${client.lastName}, ${client.firstName} (${client.staffId || data.client.patientId})`; // "Last name, First name (client id)"
        return [clientName, facility, description, vaccineType, location, lotNumber, declinedFormatted, declinedReason, dateFormatted, expiryFormatted];
      };
      let data = await this.getImmunizationExport();

      if (!this.immunizationsSection.exporting) {
        return;
      }
      data = data.flat().map(convertDataToColumns);
      this.createExcelFile("report.xlsx", columns, data);
      this.immunizationsSection.exporting = false;
      this.immunizationsSection.exportingErrorDialog = false;
    },
    createExcelFile: function (fileName, columns, data) {
      const detailsRow = util.generateExportDetailsRow();
      data.unshift(columns);
      data.unshift(detailsRow);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    exportSummaryToExcel() {
      this.summarySection.loading = true;
      const columns = [this.graphSection.groupBy, ...this.summarySection.columns, "Total"];
      const data = this.summarySection.data.map((row) => {
        return [row.label, ...this.summarySection.columns.map((period) => row[period].count), row.summationColumn];
      });
      this.createExcelFile("report.xlsx", columns, data);
      this.summarySection.loading = false;
    },
    // use admin date filter instead of expiry date
    useAdministrationDateFilter() {
      let index = this.global.filterKeys.indexOf("expiryDate");

      if (index !== -1) {
        this.global.filterKeys.splice(index, 1, "immunizationDate");
      }
      const filters = {
        ...this.global.filters,
        immunizationDate: [this.global.defaultDateStart, moment().toDate()],
      };
      delete filters.expiryDate;
      this.formatFilters(filters);
    },
    // use expiry date filter instead of admin date
    useExpiryDateFilter() {
      let index = this.global.filterKeys.indexOf("immunizationDate");

      if (index !== -1) {
        this.global.filterKeys.splice(index, 1, "expiryDate");
      }
      const filters = {
        ...this.global.filters,
        expiryDate: [this.global.defaultDateStart, moment().toDate()],
      };
      delete filters.immunizationDate;
      this.formatFilters(filters);
    },
    openSyncImmunizations() {
      this.immunizationsSection.immunizationSyncVisible = true;
    },
    closeImmunizationSync() {
      this.immunizationsSection.immunizationSyncVisible = false;
    },
  },
  async created() {
    console.clear();
    this.global.facilities = this.$configStore.data.sites;
    this.global.canSync = this.$configStore.pccIntegrationEnabled() && auth.canModify();
  },
};
</script>
