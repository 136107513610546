<template>
  <div class="symptom-details">
    <filters-section :showLocation="true" :showCurrentLocationToggle="true" :filterDisplayKeys="getFilterKeys()" :moreFilters="global.additionalFilters" :dateRangeStart="global.defaultDateStart" v-on:change="formatFilters"> </filters-section>

    <page-section title="Graph" v-loading="graphSection.loading">
      <div style="margin: 10px">
        <donut-chart :title="graphSection.title" :innerLevel="graphSection.innerLevel" :outerLevel="graphSection.outerLevel" :innerLevelName="graphSection.innerLevelName" :outerLevelName="graphSection.outerLevelName" />
      </div>
    </page-section>

    <page-section title="Summary" v-loading="summarySection.loading">
      <template slot="title">
        <el-button size="small" @click="exportSummaryToExcel">Export Data</el-button>
      </template>
      <el-table :data="summarySection.data">
        <el-table-column prop="label" :label="groupBy"></el-table-column>
        <template v-for="colKey in summarySection.columns">
          <el-table-column :key="colKey" :label="colKey">
            <template scope="scope">
              {{ scope.row[colKey] ? scope.row[colKey] : "--" }}
            </template>
          </el-table-column>
        </template>
        <el-table-column label="Total">
          <template scope="scope">
            {{ scope.row.summationColumn }}
          </template>
        </el-table-column>
      </el-table>
    </page-section>

    <page-section :title="`Analysis Details (${detailsSection.pagination.total})`" v-loading="!detailsTableReady">
      <template slot="title">
        <el-button size="small" @click="exportObservationsToExcel()">Export Data</el-button>
        <el-pagination
          @size-change="
            (size) => {
              detailsSection.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              detailsSection.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="detailsSection.pagination.size"
          layout="sizes, prev, pager, next"
          :total="detailsSection.pagination.total"
        >
        </el-pagination>
      </template>
      <el-table :data="detailsSection.data">
        <el-table-column label="Name" width="150" fixed>
          <template scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
            ({{ scope.row.client.staffId || scope.row.client.patientId }})
          </template>
        </el-table-column>
        <el-table-column prop="facility" label="Facility" width="200" v-if="$configStore.data.sites.length > 1"> </el-table-column>
        <el-table-column prop="location" label="Onset Location" width="175"> </el-table-column>
        <el-table-column prop="infection" label="Infection Site/Type" width="200"> </el-table-column>
        <el-table-column prop="status" label="Status" width="150"> </el-table-column>
        <el-table-column label="Symptoms" width="200">
          <template scope="scope">
            <el-tag v-for="s in scope.row.symptoms" :key="s.displayText" :type="s.tagType">{{ s.displayText }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Outcome" width="200">
          <template scope="scope">
            <div>{{ scope.row.outcome }}{{ scope.row.outcomeSubType ? `/ ${scope.row.outcomeSubType}` : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="primaryOrganism" label="Primary Organism" width="200"> </el-table-column>
        <el-table-column prop="treatment" label="Treatment" width="200"> </el-table-column>
        <el-table-column prop="onsetDateFormatted" label="Onset Date" min-width="100"> </el-table-column>
      </el-table>
    </page-section>

    <progress-overlay v-if="detailsSection.exportingObservations" :progress="detailsSection.observationExportProgress" v-on:progress-cancelled="cancelObservationExport" title="Download In Progress"></progress-overlay>

    <el-dialog title="Error, please try again" v-model="detailsSection.loadingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error loading Observations. Please click refresh to try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            getDetails();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="Error, please try again" v-model="detailsSection.exportingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error exporting Observations. Please try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            exportObservationsToExcel();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import XLSX from "xlsx";
import util from "../../../util";
import ProgressOverlay from "../../../components/ProgressBarOverlay";
import FiltersSection from "../../Shared/FiltersSection";
import PageSection from "../../Shared/PageSection";
import DonutChart from "../../Shared/DonutChart.vue";

export default {
  name: "symptom-detail-page",
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    "progress-overlay": ProgressOverlay,
    "donut-chart": DonutChart,
  },
  watch: {
    "detailsSection.pagination.size"() {
      this.getDetails();
    },
    "detailsSection.pagination.page"() {
      this.getDetails();
    },
  },
  computed: {
    detailsTableReady() {
      return this.global.medications !== null && !this.detailsSection.loading;
    },
  },
  data() {
    return {
      global: {
        filters: {},
        defaultDateStart: moment().subtract(90, "days").toDate(),
        sites: [],
        facilities: [],
        additionalFilters: ["origins", "employeeTypes"],
        medications: null,
        reverseMedications: {},
      },
      summarySection: {
        loading: true,
        data: [],
      },
      detailsSection: {
        loading: true,
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        exportingObservations: false,
        observationExportProgress: 0,
        loadingErrorDialog: false,
        exportingErrorDialog: false,
        data: [],
      },
      graphSection: {
        loading: true,
        outerLevelName: "Symptom",
        innerLevelName: "Infection Site",
        title: "Symptom Detail",
        outerLevel: [],
        innerLevel: [],
      },
      groupBy: "Symptoms",
    };
  },
  methods: {
    getFilterKeys() {
      return ["infectionOnsetDate", "useCurrentLocation", "symptomaticInfectionSites", "resolutions", "primaryOrganisms", "caseStatus", "clientType", "clientStatus", "symptoms"];
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "units":
                k = "unitIds";
                break;
              case "infectionSites":
                v = v[0] === 0 ? ["all"] : [v[0].type];
                break;
              case "resolutions":
                k = "outcomes";
                v = v[0] === 0 ? ["all"] : [v[0]];
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      this.global.filters.onsetDateFrom = moment(filterValues.infectionOnsetDate[0]).startOf("day").format();
      this.global.filters.onsetDateTo = moment(filterValues.infectionOnsetDate[1]).endOf("day").format();
      if (filterValues.symptomaticInfectionSites) {
        this.global.filters.infectionSites = filterValues.symptomaticInfectionSites;
      }
      delete this.global.filters.symptomaticInfectionSites;

      if (rawFilters.infectionSites && rawFilters.infectionSites[1]) this.global.filters.infectionSiteSubTypes = [rawFilters.infectionSites[1]];
      if (rawFilters.resolutions && rawFilters.resolutions[1]) this.global.filters.outcomeSubTypes = [rawFilters.resolutions[1]];
      delete this.global.filters.infectionOnsetDate;
      this.refreshAllData();
    },
    tagType: function (symptom) {
      const palette = ["success", "primary", "warning", "danger"];
      return palette[symptom.colorCode] || "gray";
    },
    generateGraphData(summary) {
      const infectionSiteColorPalette = {
        Respiratory: "blue",
        GI: "green",
        BSI: "red",
        UTI: "orange",
        Skin: "pink",
        ARO: "black",
        AUTI: "orange",
        EENT: "purple",
        Other: "grey",
        Screening: "brown",
      };
      const infectionSiteList = [];
      const symptomList = [];
      summary.forEach((value) => {
        const infectionSite = infectionSiteList.find((item) => item.name === value.infectionSite);
        if (infectionSite) {
          infectionSite.y += value.total;
        } else {
          infectionSiteList.push({
            name: value.infectionSite,
            y: value.total,
            color: infectionSiteColorPalette[value.infectionSite],
          });
        }
        symptomList.push({
          name: value.symptom,
          y: value.total,
          color: infectionSiteColorPalette[value.infectionSite],
        });
      });
      this.graphSection.innerLevel = infectionSiteList;
      this.graphSection.outerLevel = symptomList;
    },
    generateSummaryTableData(summary) {
      this.summarySection.data = [];
      this.summarySection.columns = [...new Set(summary.map((value) => value.infectionSite))];
      const summationRow = { label: "Total", summationColumn: 0 };
      summary.forEach((value) => {
        let row = this.summarySection.data.find((item) => item.label === value.symptom);
        if (row) {
          row[value.infectionSite] += value.total;
        } else {
          row = {
            label: value.symptom,
            ...this.summarySection.columns.reduce((acc, infectionSite) => {
              acc[infectionSite] = infectionSite === value.infectionSite ? value.total : 0;
              return acc;
            }, {}),
          };
          this.summarySection.data.push(row);
        }
        // Totals
        row.summationColumn = row.summationColumn || 0;
        summationRow[value.infectionSite] = summationRow[value.infectionSite] || 0;
        row.summationColumn += value.total;
        summationRow[value.infectionSite] += value.total;
        summationRow.summationColumn += value.total || 0;
      });
      this.summarySection.data.push(summationRow);
    },
    generateTreatments(record) {
      const values = record.medications
        .map((med) => {
          const { frequency, dosage, medication } = med;
          return `${medication || ""} ${dosage || ""} ${frequency || ""}`.trim();
        })
        .join(", ");
      return values;
    },
    generateDetailsTableData(record) {
      let model = { ...record };
      model.onsetDateFormatted = moment(record.onsetDate).format(this.$configStore.dateFormat());
      model.facility = this.global.facilities.filter((s) => s.id === Number(record.facId))[0].displayText;
      model.status = record.status ? "Confirmed" : "Suspected";
      model.symptoms = record.symptoms.map((s) => {
        return { ...s, tagType: this.tagType(s) };
      });
      model.treatment = this.generateTreatments(record);

      const { infectionSite, infectionType } = model;
      model.infection = `${infectionSite}${infectionType ? ` / ${infectionType}` : ""}`;

      const { firstName, lastName } = model.client;
      model.name = [lastName, firstName].join(", ");
      return model;
    },
    exportSummaryToExcel() {
      this.summarySection.loading = true;
      const columns = ["Symptoms", ...this.summarySection.columns, "Total"];
      const data = this.summarySection.data.map((row) => {
        return [row.label, ...this.summarySection.columns.map((period) => row[period]), row.summationColumn];
      });
      this.createExcelFile("report.xlsx", columns, data);
      this.summarySection.loading = false;
    },
    async exportObservationsToExcel() {
      this.detailsSection.exportingObservations = true;
      this.detailsSection.observationExportProgress = 0;
      let pageProgress = 0;
      let hasMorePages = true;
      const size = 1000;
      const columns = ["Name", "Staff ID", "Patient ID", "Infection Site/Type", "Status", "Symptoms", "Outcome", "Outcome Detail", "Facility", "Location", "Primary Organism", "Result", "Treatment", "Onset Date"];

      const convertDataToColumns = (json) => {
        const data = this.generateDetailsTableData(json);
        const { name, infection, status, symptoms, outcome, outcomeSubType, facility, location, primaryOrganism, treatment, result, onsetDateFormatted } = data;
        return [name, data.client.staffId || null, data.client.patientId || null, infection, status, symptoms.map((s) => s.displayText).join(", "), outcome, outcomeSubType, facility, location, primaryOrganism, result, treatment, onsetDateFormatted];
      };

      const getPageOfData = async (page) => {
        return await this.$http
          .post(`${window.CONFIG.infection_api}/search`, {
            ...this.global.filters,
            page,
            size,
          })
          .then((response) => response.body);
      };
      let data = [];
      while (hasMorePages) {
        this.detailsSection.exportingErrorDialog = false;
        if (!this.detailsSection.exportingObservations) {
          this.cancelObservationExport();
          break;
        }
        const json = await getPageOfData(pageProgress + 1).catch((err) => {
          if (err.status === 0) {
            this.cancelObservationExport();
            this.detailsSection.exportingErrorDialog = true;
          }
        });
        this.detailsSection.observationExportProgress = Math.round(((pageProgress + 1) / json.totalPages) * 100);
        if (json.totalPages > pageProgress + 1) {
          pageProgress++;
        } else {
          hasMorePages = false;
        }
        data.push(json.content);
      }
      if (!this.detailsSection.exportingObservations) {
        return;
      }
      data = data.flat().map(convertDataToColumns);
      this.createExcelFile("report.xlsx", columns, data);
      this.detailsSection.exportingObservations = false;
      this.detailsSection.exportingErrorDialog = false;
    },
    createExcelFile: function (fileName, columns, data) {
      const detailsRow = util.generateExportDetailsRow();
      data.unshift(columns);
      data.unshift(detailsRow);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    cancelObservationExport() {
      this.detailsSection.exportingObservations = false;
      this.detailsSection.observationExportProgress = 0;
    },
    async getDetails() {
      this.detailsSection.loading = true;
      const json = await this.$http
        .post(`${window.CONFIG.infection_api}/search`, {
          ...this.global.filters,
          page: this.detailsSection.pagination.page,
          size: this.detailsSection.pagination.size,
        })
        .then((response) => response.body)
        .catch((err) => {
          if (err.status === 0) {
            this.detailsSection.loadingErrorDialog = true;
          }
          this.detailsSection.loading = false;
          return {
            page: 0,
            content: [],
            totalElements: 0,
          };
        });

      this.detailsSection.pagination.total = json.totalElements;
      this.detailsSection.data = json.content.map(this.generateDetailsTableData);
      this.detailsSection.loading = false;
    },
    async getSummary() {
      const summary = await this.$http
        .post(`${window.CONFIG.infection_api}/symptom/summary`, {
          ...this.global.filters,
        })
        .then((response) => response.body)
        .catch(() => {
          this.summarySection.loading = false;
          this.graphSection.loading = false;
          return [];
        });
      this.generateSummaryTableData(summary);
      this.generateGraphData(summary);
      this.graphSection.loading = false;
      this.summarySection.loading = false;
    },
    async refreshAllData() {
      if (this.detailsTableReady) {
        this.getDetails();
      }
      this.getSummary();
    },
  },
  async created() {
    console.clear();
    this.global.facilities = this.$configStore.data.sites;
    await this.$http
      .get(`${window.CONFIG.treatement_api}/config`)
      .then((resp) => resp.json())
      .then((json) => [...json.medications, ...json.archivedMedications])
      .then((meds) => {
        this.global.medications = util.arrayToObj(meds, "id");
        this.global.reverseMedications = [...meds].reduce((hash, curr) => {
          hash[curr.displayText] = hash[curr.displayText] || [];
          hash[curr.displayText] = [...hash[curr.displayText], curr.id];
          return hash;
        }, {});
        this.getDetails();
      });
  },
};
</script>

<style>
.symptom-details {
  --status-missed: #ff5050;
  --status-complete: #00d560;
  --status-incomplete: #ffcc00;
}
.symptom-details .outcome {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: var(--status-missed);
}
.symptom-details .complete {
  background-color: var(--status-complete);
}
.symptom-details .incomplete {
  background-color: var(--status-incomplete);
}
.symptom-details .complianceToggle {
  margin-right: 10px;
}
.symptom-details .comments {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.symptom-details .bracket {
  color: #a7a7a7;
}
</style>
