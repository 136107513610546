<template>
  <div class="ppe">
    <FiltersSection :showLocation="true" :filterDisplayKeys="getFilterKeys()" :dateRangeStart="global.defaultDateStart" :showPlaceholderUnits="true" v-on:change="formatFilters">
      <el-button slot="title" size="small" @click="launch" type="info">Launch PPE App</el-button>
      <el-button slot="title" size="small" @click="downloadReport">Download Report</el-button>
    </FiltersSection>
    <div class="toPrint" ref="toPrint">
    <img v-if="displayLogo" class="logo" alt="HealthConnex" src="/static/logo-dark-transparent.svg" />
    <PageSection title="Graph" v-loading="graphSection.loading">
      <template slot="title">
        <div v-if="graphSection.chartType !== 'pie'">
        <span class="label">Group by</span>
        <el-radio-group v-model="graphSection.groupBy" size="small" @change="getSummary">
          <el-radio-button label="Moment"></el-radio-button>
          <el-radio-button label="Observation Point"></el-radio-button>
          <el-radio-button label="Audit Type"></el-radio-button>
          <el-radio-button label="HCW Type"></el-radio-button>
          <el-radio-button label="Observer"></el-radio-button>
          <el-radio-button label="Unit"></el-radio-button>
          <el-radio-button label="Facility"></el-radio-button>
        </el-radio-group>
        <span v-if="graphSection.chartType !== 'report'" class="label">and</span>
        <el-radio-group v-if="graphSection.chartType !== 'report'" v-model="graphSection.timeScale" size="small" @change="getSummary">
          <el-radio-button label="Week"></el-radio-button>
          <el-radio-button label="Month"></el-radio-button>
          <el-radio-button label="Quarter"></el-radio-button>
          <el-radio-button label="Year"></el-radio-button>
        </el-radio-group>
        </div>
        <span class="label">Chart Type</span>
          <el-select size="mini" v-model="graphSection.chartType" placeholder="stackedColumn" @change="getSummary">
            <el-option label="Stacked Column Chart" value="stackedColumn"> </el-option>
            <el-option label="Pie Chart" value="pie"> </el-option>
            <el-option label="Bar Chart" value="bar"> </el-option>
            <el-option label="Column Chart" value="column"> </el-option>
            <!-- <el-option label="Line Chart" value="line"> </el-option> -->
            <el-option label="Report" value="report"> </el-option>
          </el-select>
      </template>
      <div class="highchart-graphs" v-if="graphSection.chartType !== 'report'">
      <stacked-column-chart v-if="graphSection.chartType === 'stackedColumn'" :title="graphSection.title" :subtitle="graphSection.subtitle" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxisTitle" :yAxisPlotLines="graphSection.plotLines" :usePerColumnLabels="true"></stacked-column-chart>
      <pie-chart v-if="graphSection.chartType === 'pie'" :title="graphSection.title" :series="graphSection.series"></pie-chart>
      <bar-chart v-if="graphSection.chartType === 'bar'" :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxisTitle"></bar-chart>
      <column-chart v-if="graphSection.chartType === 'column'" :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxisTitle"></column-chart>
      <!-- <line-chart v-if="graphSection.chartType === 'line'" :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxisTitle"></line-chart> -->
      </div>
      <el-table v-if="graphSection.chartType === 'report'" :data="summarySection.data">
        <el-table-column prop="label" :label="graphSection.groupBy"></el-table-column>
          <el-table-column label="Compliance">
            <template scope="scope">
              <div class="progress-border">
                <div class="progress-fill" :style="{ width: (summarySection.showTrueCompliance ? scope.row.totals.percentTrueCompliance : scope.row.totals.percent) + '%' }" :percentage="(summarySection.showTrueCompliance ? scope.row.totals.percentTrueCompliance : scope.row.totals.percent) * 100"></div>
              </div>
              <!-- <sparkline-chart :series="[generateSparklineChartData(scope.row)]" :xAxisCategories="graphSection.xAxis"></sparkline-chart> -->
            </template>            
          </el-table-column>
          <el-table-column label="%">
            <template scope="scope">
                <div v-html="summarySection.showTrueCompliance ? scope.row.totals.percentTrueCompliance : scope.row.totals.percent"></div>
            </template>
          </el-table-column>
        <el-table-column label="Complied / Total">
          <template scope="scope">
            <div v-html="displayComplianceTotalFraction(scope.row.totals)"></div>
          </template>
        </el-table-column>
      </el-table>
    </PageSection>
    <PageSection title="Summary" v-loading="summarySection.loading">
      <template slot="title">
        <span class="complianceToggle">
          <span>Show strict compliance</span>
          <el-switch v-model="summarySection.showTrueCompliance" v-on:change="changeComplianceCalculation" on-color="#13ce66" on-text="" off-text=""> </el-switch>
        </span>
        <el-button size="small" class="do-not-print" @click="exportSummaryToExcel">Export Data</el-button>
      </template>
      <el-table :data="summarySection.data">
        <el-table-column prop="label" :label="graphSection.groupBy"></el-table-column>
        <template v-for="colKey in summarySection.columns">
          <el-table-column :key="colKey" :label="String(colKey)">
            <template scope="scope">
              <div v-html="renderCompliance(scope.row[colKey])"></div>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="Total">
          <template scope="scope">
            <div v-html="renderCompliance(scope.row.totals)"></div>
          </template>
        </el-table-column>
      </el-table>
    </PageSection>
    </div>
    <PageSection class="do-not-print" :title="`Observations (${detailsSection.pagination.total})`" v-loading="detailsSection.loading">
      <template slot="title">
        <el-button size="small" v-if="global.permissions.canModify" @click="addObservation">+ New Observation</el-button>
        <el-button size="small" @click="viewInformation">PPE Information</el-button>
        <el-button size="small" @click="exportObservationsToExcel">Export Data</el-button>
        <el-pagination
          @size-change="
            (size) => {
              detailsSection.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              detailsSection.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="detailsSection.pagination.size"
          layout="sizes, prev, pager, next"
          :total="detailsSection.pagination.total"
        >
        </el-pagination>
      </template>
      <el-table :data="detailsSection.data" @row-click="editObservation">
        <el-table-column prop="data.observer" label="Observer" width="200" fixed> </el-table-column>
        <el-table-column prop="data.facility" label="Facility" width="120"> </el-table-column>
        <el-table-column prop="data.unit" label="Unit" width="120"> </el-table-column>
        <el-table-column prop="collectionDateFormatted" label="Collection Date" width="140"> </el-table-column>
        <el-table-column prop="auditType" label="Audit Type" width="100"> </el-table-column>
        <el-table-column prop="hcwType" label="HCW Type" width="100"> </el-table-column>
        <el-table-column prop="complianceScore" label="Compliance" width="100">
          <template scope="scope"> {{ scope.row.compliance.score }}% ({{ scope.row.compliance.yes }}/{{ scope.row.compliance.yes + scope.row.compliance.no }}) </template>
        </el-table-column>
        <template v-for="point in global.observationPoints">
          <el-table-column :key="point" v-bind:label="point.split(' - ')[0]" width="125">
            <template scope="scope">
              {{ scope.row.observationPoints[point] === "Not Observed" ? "--" : scope.row.observationPoints[point] }}
            </template>
          </el-table-column>
        </template>
        <el-table-column prop="createdDateFormatted" label="Created Date" width="120"> </el-table-column>
        <el-table-column v-if="global.permissions.PPE_READ_COMMENTS" label="Comments" min-width="300">
          <template scope="scope">
            <div class="comments">{{ scope.row.data.comments }}</div>
          </template>
        </el-table-column>
        <el-table-column width="75" fixed="right" v-if="global.permissions.PPE_DELETE">
          <template scope="scope">
            <el-button v-if="!scope.row.isDeleted" @click="deleteAudit($event, scope.row.id)" size="mini" icon="delete"></el-button>
            <span v-else>Deleted</span>
          </template>
        </el-table-column>
      </el-table>
    </PageSection>
    <Editor v-if="detailsSection.editObservationDialogisVisible" :values="detailsSection.editFormValues" :title="detailsSection.editFormValues ? 'Edit Observation' : 'Add Observation'" v-on:editor-close="cancelObservationEditor" v-on:editor-save="closeObservationEditor" />
    <progress-overlay v-if="detailsSection.exportingObservations" :progress="detailsSection.observationExportProgress" v-on:progress-cancelled="cancelObservationExport" title="Download In Progress"></progress-overlay>
  </div>
</template>

<script>
import auth from "../../auth";
import util from "../../util";
import moment from "moment";
import XLSX from "xlsx";
import ProgressOverlay from "../../components/ProgressBarOverlay";
import FiltersSection from "../Shared/FiltersSection.vue";
import PageSection from "../Shared/PageSection.vue";
import StackedColumnChart from "../Shared/StackedColumnChart";
import Editor from "./Editor.vue";
import PieChart from "../Shared/PieChart";
import BarChart from "../Shared/BarChart";
import ColumnChart from "../Shared/ColumnChart"
import LineChart from "../Shared/LineChart";
import SparklineChart from "../Shared/SparklineChart.vue";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export default {
  name: "ppe-page",
  components: {
    FiltersSection,
    PageSection,
    "progress-overlay": ProgressOverlay,
    "stacked-column-chart": StackedColumnChart,
    "pie-chart": PieChart,
    "bar-chart": BarChart,
    "column-chart": ColumnChart,
    "line-chart": LineChart,
    "sparkline-chart": SparklineChart,
    Editor,
  },
  watch: {
    "detailsSection.pagination.size"() {
      this.getObservations();
    },
    "detailsSection.pagination.page"() {
      this.getObservations();
    },
  },
  data() {
    return {
      global: {
        permissions: {
          canModify: auth.canModify(),
          PPE_CREATE: false,
          PPE_READ: false,
          PPE_UPDATE: false,
          PPE_DELETE: false,
        },
        filters: {},
        defaultDateStart: moment().subtract(7, "days").toDate(),
        sites: [],
        audits: [],
        momentGroups: [],
        observationPoints: [],
        criteria: {},
      },
      summarySection: {
        loading: true,
        data: [],
        showTrueCompliance: false,
        exportModels: {},
        getGroupBy: {
          Moment: "sectionName",
          "Observation Point": "observationPoint",
          "Audit Type": "auditType",
          "HCW Type": "hcw",
          Unit: "unit",
          Facility: "facility",
          Observer: "observer",
        },
        createGroupBy: {
          Moment: "sectionName",
          "Observation Point": "observation_point",
          "Audit Type": "audit_type",
          "HCW Type": "hcw",
          Unit: "unit",
          Facility: "facility",
          Observer: "observer",
        },
      },
      detailsSection: {
        loading: true,
        editFormValues: null,
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        editObservationDialogisVisible: false,
        exportingObservations: false,
        observationExportProgress: 0,
        data: [],
      },
      graphSection: {
        loading: true,
        groupBy: "Moment",
        timeScale: "Week",
        series: [],
        title: "PPE Compliance",
        subtitle: "",
        yAxisTitle: "% Compliance",
        xAxis: [],
        plotLines: [],
        chartType: "stackedColumn",
      },
      displayLogo: false,
    };
  },
  methods: {
    launch() {
      open(`${window.CONFIG.ipac_web_host}${window.CONFIG.ipac_web_port}/ipac/ppe`, "_blank");
    },
    async downloadReport() {
      this.displayLogo = true;
      await this.$nextTick();
      this.displayLogo = false;
      const canvas = await html2canvas(this.$refs.toPrint);
      const data = canvas.toDataURL('image/png');
      const padding = 10;
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width + 2 * padding, canvas.height + 2 * padding]
      })
      pdf.addImage(data, 'PNG', padding, padding, canvas.width, canvas.height);
      pdf.save('report.pdf');
    },
    getFilterKeys() {
      let filters = ["collectionDate", "auditType", "hcwType", "observers"];
      if (this.global.permissions.PPE_DELETE) {
        filters.push("showDeleted");
      }
      return filters;
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "hcwType":
                k = "hcws";
                break;
              case "auditType":
                k = "auditTypes";
                break;
              case "units":
                k = "unitIds";
                break;
              case "facilities":
                k = "facilityIds";
                break;
              case "momentBySection":
                k = "sectionNames";
                v = [v];
                break;
              case "showDeleted":
                if (v === "deleted") {
                  k = "isDeleted";
                  v = true;
                } else {
                  k = "includeDeleted";
                  v = v === "active_and_deleted";
                }
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      this.global.filters.recordedLocalDatetimeStart = moment(filterValues.collectionDate[0]).startOf("day").format();
      this.global.filters.recordedLocalDatetimeEnd = moment(filterValues.collectionDate[1]).endOf("day").format();
      // if (filterValues.observers.length === 0) delete this.global.filters.observers
      delete this.global.filters.collectionDate;
      delete this.global.filters.momentBySection;
      delete this.global.filters.showDeleted;
      this.refreshAllData();
    },
    dateToTimeScale(dateObj) {
      switch (this.graphSection.timeScale) {
        case "Week":
          return `W${moment(dateObj).format("WW-YYYY")}`;
        case "Month":
          return `${moment(dateObj).format("YYYY-MM")}`;
        case "Quarter":
          return `Q${moment(dateObj).format("Q-YYYY")}`;
        default:
          return `${moment(dateObj).format("YYYY")}`;
      }
    },
    changeComplianceCalculation(toggle) {
      this.summarySection.showTrueCompliance = toggle;
      this.getSummary();
    },
    async deleteAudit(event, id) {
      event.stopPropagation();
      this.$confirm("Delete this record?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          this.detailsSection.loading = true;
          await this.$http.delete(`${window.CONFIG.ppe_api}/audit/${id}`).catch(() => {
            this.detailsSection.loading = false;
          });
          this.refreshAllData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    viewInformation() {
      window.open("/static/ppe-recommended-steps.pdf");
    },
    getMomentsFromObservation(observation) {
      const timestamp = this.dateToTimeScale(Date.parse(observation.modifiedTimestamp));
      const moments = { timestamp };
      observation.data.sections.forEach((s) => {
        const moment = s.name;
        moments[moment] = {};
        s.questions.forEach((q) => {
          if (q.response && q.response.label) {
            const answer = q.response.label;
            moments[moment][answer] = moments[moment][answer] || 0;
            moments[moment][answer] += 1;
          }
        });
      });
      return moments;
    },
    generateStackedColumnChartData(groupedTotals, timePeriods) {
      const colors = {
        no: "#ff5050",
        yes: "#00D560",
        incomplete: "#ffcc00",
        placeholder: "#cccccc",
      };
      const generateSeries = (group, periods, seriesName) => {
        return {
          name: `${group} - ${seriesName}`,
          stack: `${group}`,
          color: colors[seriesName],
          data: timePeriods.map((period) => {
            const value = Math.round((periods[period][seriesName] / periods[period].total) * 100);
            const normalized = Number.isNaN(value) ? 0 : value;
            return {
              y: normalized,
              dataLabels: {
                enabled: normalized > 0,
                format: "{y}%",
              },
            };
          }),
        };
      };
      let orderedTotals = [...groupedTotals.entries()];
      this.graphSection.xAxis = timePeriods;

      this.graphSection.subtitle =
      `
        <svg width="10" height="10"><circle cx="5" cy="5" r="5" fill="${colors.yes}" /></svg><span style="margin-left:5px; margin-right: 15px;">Compliant</span>
        <svg width="10" height="10"><circle cx="5" cy="5" r="5" fill="${colors.incomplete}" /></svg><span style="margin-left:5px; margin-right: 15px;">Non-Compliant</span>
        <svg width="10" height="10"><circle cx="5" cy="5" r="5" fill="${colors.no}" /></svg><span style="margin-left:5px; margin-right: 15px;">Missed</span>
      `;

      this.graphSection.series = orderedTotals.flatMap(([group, periods]) => {
        return [
          {
            name: `${group} - placeholder`,
            stack: `${group}`,
            color: colors.placeholder,
            data: timePeriods.map((period) => {
              return periods[period].total === 0 ? {
                y: 100,
                className: "placeholder",
                dataLabels: {
                  enabled: false,
                },
              } : null;
            }),
          },
          generateSeries(group, periods, "no"),
          generateSeries(group, periods, "incomplete"),
          generateSeries(group, periods, "yes"),
        ];
      });
    },
    generatePieChartData(summary) {
      const colors = {
        no: "#ff5050",
        yes: "#00D560",
        incomplete: "#ffcc00",
        placeholder: "#cccccc",
      };

      this.graphSection.series = [];
      for (const complianceType in summary) {
        this.graphSection.series.push({
          name: complianceType,
          y: summary[complianceType].reduce((acc, value) => {
            acc += value.count;
            return acc;
          }, 0),
          color: colors[complianceType]
        });
      }
    },
    generateBarChartData(groupedTotals, timePeriods) {
      const colors = [
        "#7fdfbf",
        "#ff0000",
        "#4eab77",
        "#5959c0",
        "#8b0000",
        "#b0b000",
        "#b03060",
        "#ff8c00",
        "#a6c6da",
        "#7fff00",
        "#60bfdf",
        "#ba55d3",
        "#0000ff",
        "#ff00ff",
        "#1e90ff",
        "#eee8aa",
        "#87cefa",
        "#ffa07a",
        "#98fb98",
        "#dda0dd",
      ];
      this.graphSection.xAxis = [...groupedTotals.keys()];
      this.graphSection.series = timePeriods.map((value, index) => {
        return {
          name: value,
          data: [],
          color: colors[index % colors.length],
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y + '%';
            },
            allowOverlap: true
          },
        }
      });
      groupedTotals.forEach(category => {
        let counter = 0;
        for (const timePeriod in category) {
          if (category[timePeriod].total > 0) {
            this.graphSection.series[counter].data.push(this.summarySection.showTrueCompliance ? category[timePeriod].percentTrueCompliance : category[timePeriod].percent);
          } else {
            this.graphSection.series[counter].data.push(null);
          }
          counter++;
        }
      })
    },
    generateLineChartData(groupedTotals, timePeriods) {
      this.graphSection.xAxis = timePeriods;
      const series = [];
      groupedTotals.forEach((value, key) => {
        const complianceOverTime = [];
        for (const timePeriod in value) {
          complianceOverTime.push(this.summarySection.showTrueCompliance ? value[timePeriod].percentTrueCompliance : value[timePeriod].percent);
        }
        series.push({
          name: key,
          data: complianceOverTime
        });
      });
      this.graphSection.series = series;
    },
    generateSparklineChartData(category) {
      const data = [];
      for (let timePeriod in category) {
        if (timePeriod !== "label" && timePeriod !== "totals") {
          data.push(category[timePeriod].yes);
        }
      }
      return {
        name: category.label,
        data: data,
      }
    },
    generateSummaryTableData(groupedTotals, timePeriods) {
      this.summarySection.data = [];
      this.summarySection.columns = [...timePeriods];
      let orderedTotals = [...groupedTotals.entries()];
      const percentage = (float) => Math.round(float * 1000) / 10;
      const totals = {
        label: "Total",
        totals: {
          yes: 0,
          incomplete: 0,
          total: 0,
          percent: 0,
          percentTrueCompliance: 0,
        },
      };
      orderedTotals.forEach(([groupLabel, value]) => {
        const row = {
          label: groupLabel,
          ...value,
          totals: {
            yes: 0,
            incomplete: 0,
            total: 0,
            percent: 0,
            percentTrueCompliance: 0,
          },
        };
        this.summarySection.columns.forEach((colKey) => {
          const obj = row[colKey];
          obj.total = obj.yes + obj.no + obj.incomplete;
          obj.percent = obj.total === 0 ? 0 : Math.round(((obj.yes + obj.incomplete) / obj.total) * 1000) / 10;
          obj.percentTrueCompliance = obj.total === 0 ? 0 : Math.round((obj.yes / obj.total) * 1000) / 10;

          row.totals.yes += obj.yes;
          row.totals.incomplete += obj.incomplete;
          row.totals.total += obj.total;
          row.totals.percent = row.totals.total === 0 ? 0 : Math.round(((row.totals.yes + row.totals.incomplete) / row.totals.total) * 1000) / 10;
          row.totals.percentTrueCompliance = row.totals.total === 0 ? 0 : Math.round((row.totals.yes / row.totals.total) * 1000) / 10;

          totals[colKey] = totals[colKey] || {
            yes: 0,
            incomplete: 0,
            total: 0,
            percent: 0,
            percentTrueCompliance: 0,
          };
          totals[colKey].yes += row[colKey].yes;
          totals[colKey].incomplete += row[colKey].incomplete;
          totals[colKey].total += row[colKey].total;
          totals[colKey].percent = totals[colKey].total === 0 ? 0 : percentage((totals[colKey].yes + totals[colKey].incomplete) / totals[colKey].total);
          totals[colKey].percentTrueCompliance = totals[colKey].total === 0 ? 0 : percentage(totals[colKey].yes / totals[colKey].total);

          totals.totals = totals.totals || {
            yes: 0,
            incomplete: 0,
            total: 0,
            percent: 0,
            percentTrueCompliance: 0,
          };
          totals.totals.incomplete += row[colKey].incomplete;
          totals.totals.yes += row[colKey].yes;
          totals.totals.total += row[colKey].total;
          totals.totals.percent = totals.totals.total === 0 ? 0 : percentage((totals.totals.yes + totals.totals.incomplete) / totals.totals.total);
          totals.totals.percentTrueCompliance = totals.totals.total === 0 ? 0 : percentage(totals.totals.yes / totals.totals.total);

        });
        this.summarySection.data.push(row);
      });
      this.summarySection.data.push(totals);
    },
    generateObservationsTableData(audits) {
      this.detailsSection.data = audits.map((audit) => {
        let model = window.structuredClone(audit);
        model.originalModel = audit;
        model.collectionDateFormatted = moment(model.data.recordedLocalTimestamp).format(`${this.$configStore.dateFormat()} HH:mm`);
        model.createdDateFormatted = moment(model.createdTimestamp).format(this.$configStore.dateFormat());
        model.auditType = model.data.auditType || "Not Selected";
        model.hcwType = model.data.hcw;
        model.observationPoints = {};
        model.compliance = this.createComplianceStats(model.data);
        const questions = model.data.sections
          .flatMap((section) => section.questions)
          .map((question) => {
            let label = question.response.label;
            if (question.response.meta) {
              const criteria = this.global.criteria[question.response.meta.label];
              const value = question.response.meta.value;
              label = value >= criteria ? label : "Non-Compliant";
            }
            question.response = question.response.meta ? `${label} (${question.response.meta.label} ${question.response.meta.value}s)` : label;
            return question;
          });
        questions
          .filter((question) => question.observationPoint)
          .forEach((question) => {
            const op = question.observationPoint;
            model.observationPoints[op] = question.response;
          });

        return model;
      });
    },
    createComplianceStats(audit) {
      let yes = 0;
      let no = 0;
      let score = 0;
      audit.sections
        .flatMap((section) => section.questions)
        .forEach((question) => {
          if (question.response.label.trim() !== "Not Observed") {
            yes += question.response.label === "Yes" ? 1 : 0;
            no += question.response.label !== "Yes" ? 1 : 0;
          }
        });
      score = yes || no ? Math.round((yes / (yes + no)) * 100) : "--";
      return { yes, no, score };
    },
    createSummary(summary) {
      const timeScale = this.graphSection.timeScale;
      const formatTime = {
        Week: (record) => `W${record.week}-${record.year}`,
        Month: (record) => `${record.year}-${moment().set("month", record.month).format("MM")}`,
        Quarter: (record) => `Q${record.quarter}-${record.year}`,
        Year: (record) => record.year,
      };

      const allTimePeriods = new Map();
      const convertAndGroup = (answer, record) => {
        const { year = "0000", quarter = "00", month = "00", week = "00" } = record;
        const row = {
          sort: `${year}.${quarter.toString().padStart(2, "0")}.${month.toString().padStart(2, "0")}.${week.toString().padStart(2, "0")}`,
          time: formatTime[timeScale](record),
          group: record[this.summarySection.createGroupBy[this.graphSection.groupBy]],
          count: { [answer]: record.count },
          data: record,
        };
        allTimePeriods.set(row.time, { yes: 0, no: 0, incomplete: 0 });
        return row;
      };

      const orderedCounts = [...summary.yes.map((r) => convertAndGroup("yes", r)), ...summary.no.map((r) => convertAndGroup("no", r)), ...summary.incomplete.map((r) => convertAndGroup("incomplete", r))].sort((a, b) => {
        return a.sort.localeCompare(b.sort);
      });

      let groupedTotals;
      const getSummaryDefaults = () => JSON.parse(JSON.stringify(Object.fromEntries(allTimePeriods)));

      if (this.graphSection.groupBy === "Moment") {
        groupedTotals = new Map(this.global.momentGroups.map((moment) => [moment, getSummaryDefaults()]));
      } else if (this.graphSection.groupBy === "Observation Point") {
        groupedTotals = new Map(this.global.observationPoints.map((op) => [op, getSummaryDefaults()]));
      } else {
        groupedTotals = new Map();
      }
      orderedCounts.forEach((row) => {
        if (!groupedTotals.has(row.group)) {
          groupedTotals.set(row.group, getSummaryDefaults());
        }
        let values = groupedTotals.get(row.group);
        values[row.time] = values[row.time] || { yes: 0, no: 0, incomplete: 0 };
        values[row.time].yes += row.count.yes || 0;
        values[row.time].no += row.count.no || 0;
        values[row.time].incomplete += row.count.incomplete || 0;
        groupedTotals.set(row.group, values);
      });
      return [groupedTotals, [...new Set(orderedCounts.map((c) => c.time))]];
    },
    exportSummaryToExcel() {
      this.summarySection.loading = true;
      const columns = [this.graphSection.groupBy, this.graphSection.timeScale, "Compliance - Yes", "Compliance - Incomplete", "Compliance - No", "Compliance Percentage", "Strict Compliance Percentage", "Total Observations"];
      let sortedGroups = [...this.summarySection.exportModels.groups.entries()];
      const data = this.summarySection.exportModels.periods.flatMap((period) => {
        return sortedGroups.map(([group, timePeriods]) => {
          return [group, period, timePeriods[period].yes, timePeriods[period].incomplete, timePeriods[period].no, `${timePeriods[period].percent}%`, `${timePeriods[period].percentTrueCompliance}%`, timePeriods[period].total];
        });
      });
      this.createExcelFile("observations.xlsx", columns, data);
      this.summarySection.loading = false;
    },
    async exportObservationsToExcel() {
      this.detailsSection.exportingObservations = true;
      this.detailsSection.loading = true;
      const columns = ["Unique ID", "Observer", "Facility", "Unit", "Collection Date", "Collection Time", "Audit Type", "HCW Type", "Compliance", ...this.global.observationPoints, "Created By", "Created Date", "Comments"];
      const convertObjectsToRows = (obj) => {
        const compliance = this.createComplianceStats(obj.data);
        return [
          obj.id,
          obj.data.observer,
          obj.data.facility,
          obj.data.unit,
          moment(obj.data.recordedLocalTimestamp).format(this.$configStore.dateFormat()),
          moment(obj.data.recordedLocalTimestamp).format("HH:mm"),
          obj.data.auditType || "Not Selected",
          obj.data.hcw,
          `${compliance.score}% (${compliance.yes}/${compliance.yes + compliance.no})`,
          ...obj.data.sections
            .flatMap((s) => s.questions)
            .filter((q) => q.observationPoint)
            .map((q) => (q.response.meta ? `${q.response.label} (${q.response.meta.label} ${q.response.meta.value}s)` : q.response.label.trim())),
          obj.createdBy,
          moment(obj.createdTimestamp).format(this.$configStore.dateFormat()),
          this.global.permissions.PPE_READ_COMMENTS ? obj.data.comments : "",
        ];
      };
      const getPageOfData = async (page, size) => {
        const params = { filters: this.global.filters, page, size };
        return await this.$http.post(`${window.CONFIG.ppe_api}/audit/search`, params).then((response) => response.body);
      };
      let page = 1;
      let data = [];
      while (this.detailsSection.loading) {
        const json = await getPageOfData(page, 5);
        data = [...data, ...json.records];
        page += 1;
        if (data.length >= json.total) {
          this.detailsSection.loading = false;
        }
      }
      data = data.map(convertObjectsToRows);
      this.createExcelFile("observations.xlsx", columns, data);
      this.detailsSection.exportingObservations = false;
    },
    createExcelFile: function (fileName, columns, data) {
      const detailsRow = util.generateExportDetailsRow();
      data.unshift(columns);
      data.unshift(detailsRow);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    cancelObservationEditor() {
      this.detailsSection.editObservationDialogisVisible = false;
    },
    closeObservationEditor() {
      this.detailsSection.editObservationDialogisVisible = false;
      this.refreshAllData();
    },
    addObservation() {
      this.detailsSection.editFormValues = null;
      this.detailsSection.editObservationDialogisVisible = true;
    },
    editObservation(record) {
      this.detailsSection.editFormValues = window.structuredClone(record.originalModel);
      this.detailsSection.editObservationDialogisVisible = true;
    },
    async getObservations() {
      this.detailsSection.loading = true;
      const params = {
        filters: this.global.filters,
        page: this.detailsSection.pagination.page,
        size: this.detailsSection.pagination.size,
      };
      const json = await this.$http
        .post(`${window.CONFIG.ppe_api}/audit/search`, params)
        .then((response) => response.body)
        .catch(() => {
          this.detailsSection.loading = false;
          return {
            page: 1,
            records: [],
            total: 0,
          };
        });
      this.detailsSection.pagination.total = json.total;
      this.generateObservationsTableData(json.records);
      this.detailsSection.loading = false;
    },
    async getSummary() {
      this.graphSection.loading = true;
      this.summarySection.loading = true;
      const timeScale = this.graphSection.timeScale;
      const summary = await this.$http
        .post(`${window.CONFIG.ppe_api}/audit/summary`, {
          filters: this.global.filters,
          hhOutcomeCriteria: this.global.criteria,
          groupBy: [this.summarySection.getGroupBy[this.graphSection.groupBy], timeScale.toLowerCase()],
        })
        .then((response) => response.body)
        .catch(() => {
          this.summarySection.loading = false;
          this.graphSection.loading = false;
          return {
            incomplete: [],
            no: [],
            yes: [],
          };
        });
      const [groups, periods] = this.createSummary(summary);
      this.summarySection.exportModels = { groups, periods };
      this.generateSummaryTableData(groups, periods);
      switch(this.graphSection.chartType) {
        case 'stackedColumn':
          this.generateStackedColumnChartData(groups, periods);
          break;
        case 'pie':
          this.generatePieChartData(summary);
          break;
        case 'bar':
          this.generateBarChartData(groups, periods);
          break;
        case 'column':
          this.generateBarChartData(groups, periods);
          break;          
        case 'line':
          this.generateLineChartData(groups, periods);
          break;
        case 'sparkline':
          this.graphSection.xAxis = periods;
          break;
      }
      this.graphSection.loading = false;
      this.summarySection.loading = false;
    },
    async refreshAllData() {
      this.getObservations();
      this.getSummary();
    },
    renderCompliance(record) {
      const perc = this.summarySection.showTrueCompliance ? record.percentTrueCompliance : record.percent;
      if (record.total === 0) {
        return "--";
      }
      return `${perc}% <span class="bracket">(${this.displayComplianceTotalFraction(record)})</span>`;
    },
    displayComplianceTotalFraction(record) {
      const numerator = record.yes + (this.summarySection.showTrueCompliance ? 0 : record.incomplete);
      const denominator = record.total;
      return `${numerator}/${denominator}`;
    },
    extractObservationPoints(config) {
      return config.data.ops.sections.flatMap((section) => section.questions).map((question) => question.observationPoint);
    },
    extractMomentGroups(config) {
      return config.data.ops.sections.flatMap((section) => section.name);
    },
  },
  async created() {
    console.clear();
    this.global.permissions.PPE_CREATE = auth.userInfo().roles.indexOf("PPE_CREATE") >= 0;
    this.global.permissions.PPE_READ = auth.userInfo().roles.indexOf("PPE_READ") >= 0;
    this.global.permissions.PPE_UPDATE = auth.userInfo().roles.indexOf("PPE_UPDATE") >= 0;
    this.global.permissions.PPE_DELETE = auth.userInfo().roles.indexOf("PPE_DELETE") >= 0;
    this.global.permissions.PPE_READ_COMMENTS = auth.userInfo().roles.indexOf("PPE_READ_COMMENTS") >= 0;

    this.global.sites = this.$configStore.data.sites;
    const latestConfig = await this.$http.get(`${window.CONFIG.ppe_api}/config/ops`).then((response) => response.body);
    this.global.observationPoints = this.extractObservationPoints(latestConfig);
    this.global.momentGroups = this.extractMomentGroups(latestConfig);
    this.global.criteria = this.$configStore.hhOutcomeCriteria();
  },
};
</script>

<style>
.ppe {
  --status-no: #ff5050;
  --status-yes: #00d560;
  --status-not-observed: lightgrey;
  --status-incomplete: #ffcc00;
}
.ppe .comments {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ppe .bracket {
  color: #a7a7a7;
}
.ppe .complianceToggle {
  margin-right: 10px;
}
.ppe .progress-border {
  height: 36px;
  width: 60%;
  position: relative;
  box-shadow: inset 0px 0px 0px 1px #b7b7b7;
  border-radius: 10px;
  overflow: hidden;
  background: #b7b7b7;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ppe .progress-fill {
  background: #659DB7;
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  left: 0;
  transition: 0.25s width linear;
  color: white;
  text-align: right;
}
.ppe .highchart-graphs {
  margin: 10px 10px 0 10px;
}
.ppe .logo {
  width: 200px;
  height: 100%;
}
</style>
